<template>
    <div class="tabbar">
        <div class="itembar" @click="tabClick(item.path)" v-for="item in typeList" :key="item.id">
            <img :src="item.path == $route.path? item.activeImg:item.img">
            <span :class="{'textColor': item.path == $route.path}">{{ item.label }}</span>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return {}
        },
        computed: {
            typeList() {
                return [
                    {
                        path: '/index/centpage',
                        img: require('@/components/tabBarIcon/shouyeSeller-hui.png'),
                        activeImg: require('@/components/tabBarIcon/shouyeSeller-lan.png'),
                        label: this.$t('button.button.home')
                    },
                    {
                        path: '/index/allcategories',
                        img: require('@/components/tabBarIcon/fenleiSeller-hui.png'),
                        activeImg: require('@/components/tabBarIcon/fenleiSeller-lan.png'),
                        label: this.$t('button.button.categories')
                    },
                    {
                        path: '/Home/SellerDashboard',
                        img: require('@/components/tabBarIcon/wodeSeller-hui.png'),
                        activeImg: require('@/components/tabBarIcon/wodeSeller-lan.png'),
                        label: this.$t('button.button.my')
                    },
                    {
                        path: '/Home/SellerConversations',
                        img: require('@/components/tabBarIcon/xiaoxiSeller-hui.png'),
                        activeImg: require('@/components/tabBarIcon/xiaoxiSeller-lan.png'),
                        label: this.$t('button.button.notification')
                    }
                ]
            }
        },
        methods: {
            tabClick: function (path) {
                this.$router.push({path: path})
            },  
        },
    }

</script>


<style scoped lang="less">
    .tabbar {
        width: 100%;
        height: 100px;
        font-size: 22px;
        font-weight: 600;
        display: flex;
        align-items: center;
        border-top: solid 2px #969696;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99999;
        background: #ffffff;
        .itembar {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25%;
            flex-shrink: 0;
            img {
                width: 40px;
                height: 40px;
                margin-bottom: 10px;
            }
            .textColor{
                color: #00ADF1 !important;
            }
        }
    }
</style>