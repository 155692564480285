<template>
	<!-- 个人数据面板 -->
	<div class="phone">
		<div class="centcents">
			<div class="centctext">
				<text>{{ $t('seller.home.Dashboard') }}</text>
			</div>
			<div class="centshang">
				<div class="itemshang">
					<div class="bigtext">
						<text>{{ buyer.cart_goods_count }}</text>
						<text>{{ $t('seller.home.products') }}</text>
					</div>
					<div class="mintext">
						<text>{{ $t('buyer.home.inYourCart') }}</text>
					</div>
				</div>
				<div class="itemshang">
					<div class="bigtext">
						<text>{{ buyer.wishlist_goods_count }}</text>
						<text>{{ $t('seller.home.products') }}</text>
					</div>
					<div class="mintext">
						<text>{{ $t('buyer.home.inYourWishlist') }}</text>
					</div>
				</div>
				<div class="itemshang">
					<div class="bigtext">
						<text>{{ buyer.order_goods_count }}</text>
						<text>{{ $t('seller.home.products') }}</text>
					</div>
					<div class="mintext">
						<text>{{ $t('buyer.home.yourOrder') }}</text>
					</div>
				</div>
			</div>
			<div class="centxia">
				<div class="twobox">
					<div class="titletext">
						<text>{{ $t('buyer.home.defaultShippingAddress') }}</text>
					</div>
					<div class="itemtext">
						<text>{{ $t('buyer.address.address') }}:</text>
						<text>{{ addressDetail.address }}</text>
					</div>
					<div class="itemtext">
						<text>{{ $t('buyer.addAddress.nation') }}:</text>
						<text>{{ addressDetail.country }}</text>
					</div>
					<div class="itemtext">
						<text>{{ $t('buyer.addAddress.stateProvince') }}:</text>
						<text>{{ addressDetail.state }}</text>
					</div>
					<div class="itemtext">
						<text>{{ $t('buyer.addAddress.city') }}:</text>
						<text>{{ addressDetail.city }}</text>
					</div>
					<div class="itemtext">
						<text>{{ $t('buyer.addAddress.postcode') }}:</text>
						<text>{{ addressDetail.postal_code }}</text>
					</div>
					<div class="itemtext">
						<text>{{ $t('buyer.addAddress.phone') }}:</text>
						<text>{{ addressDetail.phone }}</text>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {defAddress,buyer} from '@/api/buyerapi'
	export default {
		data(){
			return {
				addressDetail: {}, //地址详情
				buyer: {}
			}
		},
		mounted(){
			this.getDefAddress()
			this.getBuyers()
		},
		methods:{
			// 获取默认详细地址
			async getDefAddress(){
				const {data: res} = await defAddress()
				this.addressDetail = res.success
			},
			async getBuyers(){
				const {data: res} = await buyer()
				this.buyer = res.success
				console.log('aaaaaaaaaa',res)
			},
		}
	}
</script>

<style scoped lang="less">
	.phone {
		.centcents {
			width: 96%;
			margin: auto;
			.centctext {
				font-size: 24px;
				height: 80px;
				line-height: 80px;
				font-family: Arial;
				font-weight: 600;
				color: #000000;
				text-align: left;
				margin-bottom: 15px;
			}
			.centshang {
				display: flex;
				flex-direction: column;
				.itemshang {
					width: 100%;
					height: 300px;
					box-sizing: border-box;
					padding-left: 70px;
					padding-top: 45px;
					border-radius: 25px;
					overflow: hidden;
					.bigtext {
						font-size: 40px;
						font-family: Arial;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 36px;
						text-align: left;
						text {
							margin-right: 5px;
						}
					}
					.mintext{
						font-size: 24px;
						color: #FFFFFF;
						text-align: left;
						margin-top: 25px;
						opacity: 0.7;
					}
				}
				.itemshang:nth-child(1){
					background-image: url('../../assets/img/buyer-dashboard-01.png');
					background-size: cover;
				}
				.itemshang:nth-child(2){
					background-image: url('../../assets/img/buyer-dashboard-02.png');
					background-size: cover;
					margin: 20px 0px;
				}
				.itemshang:nth-child(3){
					background-image: url('../../assets/img/buyer-dashboard-03.png');
					background-size: cover;
				}
			}
			.centxia {
				display: flex;
				justify-content: space-between;
				margin-top: 25px;
				.twobox {
					width: 100%;
					min-height: 200px;
					background: #FFFFFF;
					border-radius: 8px;
					box-sizing: border-box;
					padding: 0 30px 30px 30px;
					.titletext  {
						font-size: 24px;
						font-family: Arial;
						font-weight: bold;
						color: #000000;
						line-height: 80px;
						text-align: left;
						border-bottom: solid 1px #CACACA;
						margin-bottom: 10px;
					}
					.itemtext {
						display: flex;
						justify-content: space-between;
						font-size: 20px;
						font-family: Arial;
						font-weight: 400;
						line-height: 60px;
						&>text:nth-child(1){
							color: #969696;
						}
					}
				}
			}
		}
	}
</style>