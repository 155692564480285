<template>
 <!-- 卖家注册 -->
	<!-- 手机端 -->
	<div class='phone'>
        <div class="centbox">
			<div class="twobox">
				<div class="titles">
					<text>{{ $t('buyer.register.personal_Info') }}</text>
				</div>
				<div class="inpbox">
					<span>{{ $t('seller.mangeProfile.fullName') }} *</span>
					<input type="text" :placeholder="$t('buyer.register.fullName')" v-model="uname">
				</div>
				<div class="inpbox">
					<span>{{ $t('seller.mangeProfile.email') }} *</span>
					<input type="text" :placeholder="$t('buyer.register.email')" v-model="uemail">
				</div>
				<div class="inpbox">
					<span>{{ $t('seller.mangeProfile.password') }} *</span>
					<input type="password" :placeholder="$t('buyer.register.password')" v-model="member_pwd">
				</div>
				<div class="inpbox">
					<span>{{ $t('buyer.register.confirmPassword') }} *</span>
					<input type="password" :placeholder="$t('buyer.register.confirmPassword')" v-model="member_pwd_two">
				</div>
				<div class="inpbox">
					<span>{{ $t('buyer.register.inviteCode') }} *</span>
					<input type="text" :placeholder="$t('buyer.register.inviteCode')" v-model="invitation_code">
				</div>
			</div>
			<div class="twobox marg">
				<div class="titles">
					<text>{{ $t('buyer.register.personal_Info') }}</text>
				</div>
				<div class="inpbox">
					<span>{{ $t('seller.shopSetting.shopName') }} *</span>
					<input type="text" :placeholder="$t('seller.shopSetting.shopName')" v-model="shop_name">
				</div>
				<div class="inpbox">
					<span>{{ $t('buyer.address.address') }} *</span>
					<input type="text" :placeholder="$t('buyer.address.address')" v-model="address">
				</div>
			</div>
			<div class="create" @click="sellerRegChange">
				<span>{{ $t('buyer.register.createAccount') }}</span>
			</div>
		</div>
    </div>
</template>
<script>
import {sellerReg} from '../../api/api.js'
import { ElMessage } from 'element-plus'
export default {
    data () {
        return {
            uname: '', //用户名
			uemail: '', //邮箱
			member_pwd:'',  //密码
			member_pwd_two: '', //确认密码
			invitation_code: '',//邀请码
			shop_name: '', //店铺名称
			address: '' //地址
        }
    },
	mounted(){
		let _this = this
		let url = window.location.href
		if(_this.getUrlParams3(url).codes) {
			_this.invitation_code = _this.getUrlParams3(url).codes
		}
	},
    methods:{
		// 获取url参数
		getUrlParams3(url){
			// \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
			let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
			let result = {};
			url.replace(pattern, ($, $1, $2)=>{
				result[$1] = $2;
			})
			return result
		},
		// 卖家注册
        async sellerRegChange(){
			if(this.uname == '') {
				return ElMessage.error('enter one user name')
			}
			if(this.uemail == '') {
				return ElMessage.error('Please enter your email address')
			}
			if(this.member_pwd == '') {
				return ElMessage.error('Please enter the password')
			}
			if(this.member_pwd_two == '') {
				return ElMessage.error('Please enter a confirmation password')
			}
			if(this.member_pwd !== this.member_pwd_two) {
				return ElMessage.error('The two passwords do not match')
			}
			if(this.invitation_code == '') {
				return ElMessage.error('Please enter the invitation code')
			}
			if(this.shop_name == '') {
				return ElMessage.error('Please enter the store name')
			}
			if(this.address == '') {
				return ElMessage.error('Please enter the address')
			}
			const {data: res} = await sellerReg({
				member_name: this.uname, //用户名称
				email: this.uemail, //邮箱
				member_pwd: this.member_pwd, //密码
				member_pwd_two: this.member_pwd_two, //确认密码
				invitation_code: this.invitation_code, //邀请码
				shop_name: this.shop_name, //店铺名称
				address: this.address //地址
			})
			if(res.status == 200) {
				ElMessage.success(res.message)
				sessionStorage.setItem('member_role', res.success.member_role)
				sessionStorage.setItem('member_token', res.success.member_token)
				setTimeout(()=>{
					this.$router.push('/Home')
				},2000)
			}else {
				ElMessage.error(res.message)
			}
		}
    }
}
</script>
<style lang="less" scoped>
	.phone {
		.centbox {
			width: 90%;
			margin: auto;
			margin-top: 60px;
			margin-bottom: 80px;
			.marg {
				margin-top: 30px;
			}
			.twobox {
				background: #FFFFFF;
				border-radius: 5px;
				width: 100%;
				box-sizing: border-box;
				padding: 30px 25px;
				.titles {
					font-size: 40px;
					font-family: Arial;
					font-weight: bold;
					text-align: center;
					height: 100px;
					line-height: 100px;
				}
				.inpbox {
					text-align: left;
					font-size: 26px;
					margin-top: 30px;
					input {
						width: 100%;
						height: 100px;
						border: solid 3px #e6e6e6;
						border-radius: 5px;
						outline: none;
						font-size: 24px;
						box-sizing: border-box;
						padding-left: 10px;
						margin-top: 10px;
					}
				}
			}
			.create {
				cursor: pointer;
				width: 100%;
				height: 80px;
				background: #00ADF1;
				border-radius: 5px;
				font-size: 30px;
				font-family: Arial;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 80px;
				text-align: center;
				margin-top: 30px;
			}
		}
	}
</style>