<template>
    <!-- 邀请 -->
    <div class="bigboxs">
        <!-- <div class="betitle">
            <span>Be a Brushing User</span>
        </div>
        <div class="basicbox">
            <div class="basicinfo">
                <span>{{ $t('seller.appliedRefundRequest.basicInfo') }}</span>
            </div>
            <div class="codebox">
                <span>Invite Code*</span>
                <input type="text" placeholder="Invite Code">
            </div>
            <div class="asabox">
                <span>Register As a Brushing User</span>
            </div>
        </div> -->
    </div>
</template>



<style lang="less" scoped>
    
</style>