<template>
    <!-- 手机端 -->
    <div class="phone">
        <div class="centbigbox">
            <!-- 轮播图 -->
            <div class="centswiper">
                <div class="swiperbox">
                    <el-carousel height="340px">
                        <el-carousel-item v-for="item in swiperList" :key="item.baner_img">
                            <img :src="item.banner_img"/>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="itembigbox">
                    <div class="itembox" v-for="item in CatetjList" :key="item.cate_id">
                        <img :src="item.cate_img" />
                        <div class="wenzi">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="centright">
                <div class="todaysDeal">
                    <text>{{ $t('seller.product.todayDeal') }}</text>
                    <text>Hot</text>
                </div>
                <div class="itemsbigbox">
                    <div class="itemsp" v-for="(item, index) in gHots" :key="index">
                        <div class="text1">
                            <text>{{ item.goods_name }}</text>
                        </div>
                        <div class="text2">
                            <text>{{ item.primary }}</text>
                        </div>
                        <img :src="item.main_img" mode="widthFix" @click="toDetailsPhone(item.id)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="tpbox">
            <div class="itemtp" v-for="(item, index) in threeAds" :key="index">
                <img :src="item" />
            </div>
        </div>
        <!-- 新产品 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.newpro') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="newShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in newProducts" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="leftnewCpPhone" />
                    <img src="../../assets/img/yjiant.png" @click="rightnewCpPhone" />
                </div>
            </div>
        </div>
        <!-- 特色产品 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.featuredProducts') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="tsShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in tsProducts" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="leftTsCpPhone" />
                    <img src="../../assets/img/yjiant.png" @click="rightTsCpPhone" />
                </div>
            </div>
        </div>
        <!-- 畅销产品 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.bestSelling') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="cxShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in cxChangpin" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="cxleftSlidePhone" />
                    <img src="../../assets/img/yjiant.png" @click="cxrightSlidePhone" />
                </div>
            </div>
        </div>
        <!-- 服装 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.womenClothingFashion') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="fzShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in fzChangpin" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="fzleftSlidePhone" />
                    <img src="../../assets/img/yjiant.png" @click="fzrightSlidePhone" />
                </div>
            </div>
        </div>
        <!-- 男装 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.menClothingFashion') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="nzShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in nzChangpin" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="nzleftSlidePhone" />
                    <img src="../../assets/img/yjiant.png" @click="nzrightSlidePhone" />
                </div>
            </div>
        
        </div>
        <!-- 电脑及配件 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.computerAccessories') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="computerShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in computerChangpin" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="computerleftSlidePhone" />
                    <img src="../../assets/img/yjiant.png" @click="computerrightSlidePhone" />
                </div>
            </div>
        </div>
        <!-- 汽车摩托车 -->
        <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.automobileMotorcycle') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="automobileShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in automobileChangpin" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="automobileleftSlidePhone" />
                    <img src="../../assets/img/yjiant.png" @click="automobilerightSlidePhone" />
                </div>
            </div>
        </div>
         <!-- 运动及户外 -->
         <div>
            <div class="btbox">
                <div class="newbt">
                    <text>{{ $t('buyer.home.sportsOutdoor') }}</text>
                </div>
                <text>top20</text>
            </div>
            <div class="newcpbox">
                <div class="scroll_wrapper" ref="portsShopPhone">
                    <div class="scroll_list">
                        <div class="itemcp" v-for="item in sportsChangpin" :key="item.id">
                            <img :src="item.main_img" @click="toDetailsPhone(item.id)" />
                            <div class="jiage" @click="toDetailsPhone(item.id)">
                                <text>{{ item.primary }}</text>
                                <el-rate v-model="item.grade" disabled />
                                <div class="mswenz">{{ item.goods_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="twojiant">
                    <img src="../../assets/img/zjiant.png" @click="sportsleftPhone" />
                    <img src="../../assets/img/yjiant.png" @click="sportsrightPhone" />
                </div>
            </div>
        </div>
        <div class="top10box">
            <div class="top10zy">
                <div class="btboxs">
                    <div class="top10">
                        <text>{{ $t('buyer.home.topCategories') }}</text>
                    </div>
                    <text>{{ $t('buyer.category.viewAll') }}</text>
                </div>
                <div class="shopbox">
                    <div class="itemshop" v-for="item in topTenList" :key="item.cate_id" @click="toCategoriey">
                        <img :src="item.cate_img" />
                        <div class="mstext">
                            <text>{{ item.name }}</text>
                            <img class="jticon" src="../../assets/img/youjiantou.png" mode="widthFix" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="top10zy maginleft">
                <div class="btboxs">
                    <div class="top10">
                        <text>{{ $t('buyer.home.topBrands') }}</text>
                    </div>
                    <text>{{ $t('buyer.category.viewAll') }}</text>
                </div>
                <div class="shopbox">
                    <div class="itemshop" v-for="item in brandList" :key="item.goods_brand_id" @click="toCategoriey">
                        <img :src="item.graphic" />
                        <div class="mstext">
                            <text>{{ item.name }}</text>
                            <img class="jticon" src="../../assets/img/youjiantou.png" mode="widthFix" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    hBanner, 
    pCate, 
    cateTj, 
    gHot, 
    newProduct, 
    TeseProduct, 
    threeAd, 
    topTen, 
    topBrand, 
    cxProduct, 
    fzProduct, 
    nzProduct, 
    computerProduct,
    automobileProduct,
    sportsProduct
} from '../../api/buyerapi.js'
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            timer: null, //定时器，方便清除
            swiperList: [], //轮播图列表
            CateList: [], //左侧分类
            CatetjList: [], //轮播图下方推荐分类
            gHots: [], //轮播图右侧的商品
            newProducts: [], //新产品
            tsProducts: [], //特色产品
            cxChangpin: [], //畅销产品
            fzChangpin: [], //服装产品
            nzChangpin: [], //男装产品
            computerChangpin: [], //电脑产品
            automobileChangpin: [], //汽车摩托车
            sportsChangpin: [], //运动及户外
            threeAds: [], //三个广告图
            topTenList: [], //top10排行
            brandList: [], //top10brands
        }
    },
    mounted() {
        this.getBanner()
        this.getpCate()
        this.getpCateTj()
        this.getgHot()
        this.newChanpin()
        this.TsProduct()
        this.getcxChangpin()
        this.getfzChangpin()
        this.getnzChangpin()
        this.computersChangpin()
        this.automobilesChangpin()
        this.sportsChangpins()
        this.sanAdimg()
        this.topTentwo()
        this.topTentwos()
    },

    methods: {
        // 获取banner图
        async getBanner() {
            const { data: res } = await hBanner()
            if (res.status == 200) {
                this.swiperList = res.success
            } else {
                ElMessage.error(res.message)
            }
            // console.log(res)
        },
        // 左侧分类
        async getpCate() {
            const { data: res } = await pCate()
            if (res.status == 200) {
                this.CateList = res.success
            } else {
                ElMessage.error(res.message)
            }
            // console.log(res)
        },
        // 轮播图下方的分类
        async getpCateTj() {
            const { data: res } = await cateTj()
            if (res.status == 200) {
                this.CatetjList = res.success
            } else {
                ElMessage.error(res.message)
            }
            // console.log(res)
        },
        // 轮播图右侧的图
        async getgHot() {
            const { data: res } = await gHot()
            if (res.status == 200) {
                this.gHots = res.success
            } else {
                ElMessage.error(res.message)
            }
            console.log('hot',res)
        },
        // 新产品
        async newChanpin() {
            const { data: res } = await newProduct()
            if (res.status == 200) {
                this.newProducts = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
        // 畅销产品
        async getcxChangpin() {
            const { data: res } = await cxProduct()
            if (res.status == 200) {
                this.cxChangpin = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
        // 女装产品
        async getfzChangpin() {
            const { data: res } = await fzProduct()
            if (res.status == 200) {
                this.fzChangpin = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
        //特色产品
        async TsProduct() {
            const { data: res } = await TeseProduct()
            if (res.status == 200) {
                this.tsProducts = res.success
            } else {
                alert(res.message)
            }
            // console.log(res)
        },
        // 男装产品
        async getnzChangpin() {
            const { data: res } = await nzProduct()
            if (res.status == 200) {
                this.nzChangpin = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
        // 电脑产品
        async computersChangpin() {
            const { data: res } = await computerProduct()
            if (res.status == 200) {
                this.computerChangpin = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
        // 汽车摩托车
        async automobilesChangpin() {
            const { data: res } = await automobileProduct()
            if (res.status == 200) {
                this.automobileChangpin = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
        // 运动及户外
        async sportsChangpins() {
            const { data: res } = await sportsProduct()
            if (res.status == 200) {
                this.sportsChangpin = res.success
            } else {
                ElMessage.error(res.message)
            }
        },
         // 去分类页面
         toCategorieyDetail() {
            this.$router.push('/index/categorieydetail')
        },
        // 三个广告图
        async sanAdimg() {
            const { data: res } = await threeAd()
            if (res.status == 200) {
                this.threeAds = res.success
            } else {
                alert(res.message)
            }
            // console.log(res)
        },
        // top10排行
        async topTentwo() {
            const { data: res } = await topTen()
            if (res.status == 200) {
                this.topTenList = res.success
            } else {
                alert(res.message)
            }
            console.log(res)
        },
        // top10排行brands
        async topTentwos() {
            const { data: res } = await topBrand()
            if (res.status == 200) {
                this.brandList = res.success
            } else {
                alert(res.message)
            }
            console.log(res)
        },
        //左箭头切换函数
        leftSlide(leftrefs,timer) {
            let left = leftrefs.scrollLeft
            let num = 0
            clearInterval(timer)
            timer = null
            timer = setInterval(() => {
                if (!left || num >= 276) {
                    clearInterval(timer)
                    timer = null
                    return
                }
                leftrefs.scrollLeft = left -= 27.6
                // 保存向左滚动距离（方便判断一次性滚动多少距离）
                num += 27.6
            }, 25)
        },
        //右箭头切换函数
        rightSlide(leftrefs,timer) {
            let left = leftrefs.scrollLeft
            // 保存元素的整体宽度
            let scrollWidth = leftrefs.scrollWidth
            // 保存元素的可见宽度
            let clientWidth = leftrefs.clientWidth
            let num = 0
            clearInterval(timer)
            timer = setInterval(() => {
                // num>=300一次性滚动距离
                if (left + clientWidth >= scrollWidth || num >= 276) {
                    clearInterval(timer)
                    return
                }
                // 给滚动盒子元素赋值向左滚动距离
                leftrefs.scrollLeft = left += 27.6
                // 保存向左滚动距离（方便判断一次性滚动多少距离）
                num += 27.6
            }, 25)
        },
        // 新产品左箭头切换
        leftnewCpPhone(){
            this.leftSlide(this.$refs.newShopPhone, this.timer)
        },
        // 新产品右箭头切换
        rightnewCpPhone(){
            this.rightSlide(this.$refs.newShopPhone, this.timer)
        },
        //特色产品左箭头切换-手机端
        leftTsCpPhone() {
            this.leftSlide(this.$refs.tsShopPhone, this.timer)
        },
        // 特色产品右箭头切换-手机端
        rightTsCpPhone() {
            this.rightSlide(this.$refs.tsShopPhone, this.timer)
        },
        // 畅销产品左箭头切换-手机端
        cxleftSlidePhone() {
            this.leftSlide(this.$refs.cxShopPhone, this.timer)
        },
        // 畅销产品右箭头切换-手机端
        cxrightSlidePhone() {
            this.rightSlide(this.$refs.cxShopPhone, this.timer)
        },
        // 服装左箭头切换-手机端
        fzleftSlidePhone() {
            this.leftSlide(this.$refs.fzShopPhone, this.timer)
        },
        // 服装右箭头切换-手机端
        fzrightSlidePhone() {
            this.rightSlide(this.$refs.fzShopPhone, this.timer)
        },
        // 男装左箭头切换-手机端
        nzleftSlidePhone() {
            this.leftSlide(this.$refs.nzShopPhone, this.timer)
        },
        // 男装右箭头切换-手机端
        nzrightSlidePhone() {
            this.rightSlide(this.$refs.nzShopPhone, this.timer)
        },
        // 电脑左箭头切换-手机端
        computerleftSlidePhone() {
            this.leftSlide(this.$refs.computerShopPhone, this.timer)
        },
        // 电脑右箭头切换-手机端
        computerrightSlidePhone() {
            this.rightSlide(this.$refs.computerShopPhone, this.timer)
        },
        // 汽车摩托车左箭头切换-手机端
        automobileleftSlidePhone() {
            this.leftSlide(this.$refs.automobileShopPhone, this.timer)
        },
        // 汽车摩托车右箭头切换-手机端
        automobilerightSlidePhone() {
            this.rightSlide(this.$refs.automobileShopPhone, this.timer)
        },
        // 运动及户外左箭头切换-手机端
        sportsleftPhone(){
            this.leftSlide(this.$refs.portsShopPhone, this.timer1)
        },
        // 运动及户外右箭头切换-手机端
        sportsrightPhone(){
            this.rightSlide(this.$refs.portsShopPhone, this.timer1)
        },
        // 分类
        toCategoriey() {
            this.$router.push('/index/categorieydetail')
        },
        // 去详情页面
        toDetailsPhone(id) {
            this.$router.push({path: '/index/shopdetailbuyer', query: {id: id}})
        }
    },
}
</script>

<style lang="less" scoped>
.phone {
    .centbigbox {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        .centswiper {
            width: 100%;
            margin: 0 15px;
            .swiperbox {
                width: 96%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .itembigbox {
                box-sizing: border-box;
                width: 96%;
                margin-right: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                font-size: 22px;
                .itembox {
                    width: 31%;
                    height: 260px;
                    background-color: #fff;
                    border-radius: 5px;
                    margin-top: 20px;
                    .wenzi {
                        width: 90%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-left: 10px;
                    }

                    img {
                        width: 70%;
                        height: 80%;
                    }
                }
            }
        }
        .centright {
            background-color: #00adf1;
            box-sizing: border-box;
            width: 96%;
            height: 100%;
            margin-left: 15px;
            margin-top: 20px;
            padding: 20px;
            .todaysDeal {
                width: 100%;
                height: 80px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #d9f3fd;
                text:nth-child(2) {
                    display: inline-block;
                    width: 67px;
                    height: 45px;
                    background: #f20022;
                    border-radius: 5px;
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    text-align: center;
                    margin-left: 10px;
                    color: #fff;
                    line-height: 45px;
                }
            }

            .itemsbigbox {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .itemsp {
                    width: 49%;
                    min-height: 360px;
                    background: #ffffff;
                    box-sizing: border-box;
                    margin-top: 20px;
                    padding: 5px 10px;
                    position: relative;
                    .text1 {
                        font-size: 24px;
                        height: 36px;
                        display: inline-block;
                        width: 96%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        text-align: left;
                    }

                    .text2 {
                        font-size: 24px;
                        width: 90%;
                        height: 26px;
                        display: inline-block;
                        text-align: left;
                        position: absolute;
                        top: 45px;
                        left: 10px;
                    }
                    img {
                        width: 60%;
                        height: 70%;
                        position: absolute;
                        top: 80px;
                        left: 19%;
                    }
                }
            }
        }
    }

    .tpbox {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        margin-left: 15px;
        cursor: pointer;
        .itemtp {
            width: 96%;
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .btbox {
        width: 100%;
        border-bottom: solid 1px #e6e6e6;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 15px;
        .newbt {
            width: auto;
            font-size: 28px;
            font-family: Arial;
            font-weight: bold;
            color: #000000;
            border-bottom: solid 4px #00adf1;
            margin-bottom: 5px;
        }

        & > text {
            padding: 5px 15px;
            background: #00adf1;
            border-radius: 5px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            color: #ffffff;
            text-align: center;
        }
    }

    .newcpbox {
        width: 96%;
        height: 315px;
        margin: auto;
        margin-top: 25px;
        position: relative;
        box-sizing: border-box;
        margin: 0 15px;
        .scroll_wrapper::-webkit-scrollbar {
            display: none;
        }

        .scroll_wrapper {
            width: 100%;
            overflow-x: scroll;
            background: #fff;

            .scroll_list {
                display: flex;

                .itemcp {
                    width: 230px;
                    padding: 10px 22px;
                    margin-top: 5px;
                    border-left: solid 1px #f4f4f4;
                    border-right: solid 1px #f4f4f4;
                    display: flex;
                    flex-shrink: 0;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    img {
                        width: auto;
                        height: 220px;
                        box-sizing: border-box;
                        padding: 10px;
                    }

                    .jiage {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        cursor: pointer;

                        & > text {
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: bold;
                            color: #53bef4;
                        }

                        .mswenz {
                            width: 100%;
                            height: 26px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            font-size: 22px;
                            -webkit-box-orient: vertical;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .twojiant {
            display: none !important;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            box-sizing: border-box;
            padding: 0 10px;
            top: 50%;
            left: 0;
            margin-top: -40px;
            & > img {
                width: 35px;
                height: 80px;
            }
        }
    }

    .top10box {
        width: 96%;
        margin: 0px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        .top10zy {
            width: 100%;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            .btboxs {
                width: 100%;
                border-bottom: solid 1px #e6e6e6;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .top10 {
                    height: 30px;
                    font-size: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 30px;
                    border-bottom: solid 4px #00adf1;
                }

                & > text {
                    padding: 5px 10px;
                    background: #00adf1;
                    border-radius: 5px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                }
            }

            .shopbox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                box-sizing: border-box;

                .itemshop {
                    width: 49%;
                    height: 160px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 10px 10px;
                    margin-top: 15px;
                    cursor: pointer;
                    
                    img:nth-child(1) {
                        max-width: 100%;
                        height: 60px;
                        flex-shrink: 0;
                    }

                    .mstext {
                        display: flex;
                        align-items: center;
                        width: 70%;
                        & > text {
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 600;
                            color: #333333;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
</style>