<template>
    <div class="allbox">
        <div class="topname">
            <span>仨的阿瑟 #2147483647</span>
            <div class="yhm">
                <span>xxlBuyer 2023-06-30 17:19:29</span>
                <span>Pending</span>
            </div>
        </div>
        <div class="areabox">
            <textarea name="" id="" cols="30" rows="10"></textarea>
        </div>
        <!-- <div class="updatebox">
            <el-upload
                v-model:file-list="fileList"
                class="upload-demo"
                action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                multiple
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="3"
                :on-exceed="handleExceed"
            >
                <div class="spanbox">
                    <span>Browse </span>
                    <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                </div>
            </el-upload>
        </div> -->
        <div class="sendbox">
            <span>Send Reply</span>
        </div>
        <div class="userbox" v-for="(item,index) in 2" :key="index">
            <div class="tops">
                <div class="topshead">
                    <img src="../../assets/img/touxiang.png">
                </div>
                <div class="yhmbox">
                    <span>仨的阿瑟 #2147483647</span>
                    <span>xxlBuyer 2023-06-30 17:19:29</span>
                </div>
            </div>
            <div class="bomtext">
                <span>Hello, this is after-sales service. Please contact online custo</span>
            </div>
        </div>
    </div>
</template>





<style lang="less" scoped>

</style>

