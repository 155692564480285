<template>
 <!-- 发票详情 -->
    <div class='bodys'>
        <div class="allbox">
            <div class="topname">
                <span>三德阿三 #21656621</span>
                <div class="userbox">
                    <span>xxBuyer 2023-06-30 17:19:29</span>
                    <span>Pending</span>
                </div>
            </div>
            <textarea name="" id=""></textarea>
            <div class="choosebox">
                <el-upload
                    v-model:file-list="fileList"
                    class="upload-demo"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                    multiple
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :limit="3"
                    :on-exceed="handleExceed"
                    list-type="picture"
                >
                    <span>Browse</span>
                    <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                </el-upload>
            </div>
            <div class="sendbox">
                <span>Send Reply</span>
            </div>
            <div class="huihuabox" v-for="(item,index) in 2" :key="index">
                <div class="tophead">
                    <img src="../../../assets/img/touxiang.jpg">
                    <div class="uname">
                        <span>三德阿瑟 #2656525451</span>
                        <span>xxBuyer 2023-06-30 17:20:20</span>
                    </div>
                </div>
                <div class="textbox">
                    <span>Hello,this is atter sallk serve Plalse contat online cutors service it you have any questions</span>
                </div>
                <div class="span1">
                    <span>download</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            
        }
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
</style>