<template>
    <div class="phone">
        <div class="bigbox">
            <div class="titles">
                <span>{{ $t('buyer.my.sentRefundRequest') }}</span>
            </div>
            <div class="tabbox" v-for="item in tableData" :key="item.id">
                <div class="itemtable">
                    <p>{{ $t('buyer.appliedRefundRequest.date') }}</p>
                    <div class="neir">
                        <span class="spans" @click="toDetail(item.id)">{{ item.refund_time }}</span>
                    </div>
                </div>
                <div class="itemtable">
                    <p>{{ $t('seller.order.orderId') }}</p>
                    <div class="neir">
                        <span>{{ item.order_no }}</span>
                    </div>
                </div>
                <div class="itemtable">
                    <p>{{ $t('seller.product.productName') }}</p>
                    <div class="neir">
                        <span>{{ item.goods_name }}</span>
                    </div>
                </div>
                <div class="itemtable">
                    <p>{{ $t('buyer.purchaseHistory.amount') }}</p>
                    <div class="neir">
                        <span>{{ item.total_price }}</span>
                    </div>
                </div>
                <div class="itemtable">
                    <p>{{ $t('buyer.supportTicket.status') }}</p>
                    <div class="neir">
                        <span class="span2">{{ item.refund_seller_status }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="fenye">
                <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    :total="100"
                    class="my-pagination"
                />
            </div> -->
        </div>
    </div>
</template>
<script>
    import {refundList} from '@/api/buyerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data() {
            return {
                tableData: []
            };
        },
        mounted(){
            this.getRefundList()
        },
        methods: {
            // 退款列表
            async getRefundList(){
                const {data: res} = await refundList()
                if(res.status == 200) {
                    this.tableData = res.success
                    console.log('已发送退款请求',res)
                }else {
                    ElMessage.error(res.message)
                }
            }
        },
    };
</script>

<style scoped lang="less">
   .phone {
        .bigbox {
            width: 96%;
            background: #fff;
            box-sizing: border-box;
            padding: 0 20px;
            border-radius: 5px;
            margin: auto;
            .titles {
                font-size: 24px;
                font-family: Arial;
                font-weight: bold;
                color: #000000;
                line-height: 60px;
                border-bottom: 1px solid #CACACA;
                text-align: left;
            }
            .tabbox {
                margin-top: 25px;
                border-bottom: 1px solid #CACACA;
                padding-bottom: 20px;
                .itemtable {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 18px;
                    min-height: 30px;
                    .neir {
                        width: 80%;
                        text-align: right;
                    }
                    .span2 {
                        display: inline-block;
                        height: 25px;
                        padding: 5px 10px;
                        background: #00ADF1;
                        border-radius: 4px;
                        color: #fff;
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FEFEFE;
                        line-height: 24px;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
        }
   }
</style>