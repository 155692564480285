<template>
    <!-- 手机端 -->
    <div class="phone">
        <div class="allbox">
            <div class="topliucheng">
                <div class="itembox dongtai">
                    <img src="../../../assets/img/cart-lan.png">
                    <span>1.{{ $t('buyer.myCart.myCart') }}</span>
                </div>
                <div class="itembox" :class="index >= 2? 'dongtai' : ''">
                    <img src="../../../assets/img/shipping-lan.png" v-if="index >= 2">
                    <img src="../../../assets/img/shipping-hui.png" v-else>
                    <span>2.{{ $t('buyer.myCart.shippingInfo') }}</span>
                </div>
                <div class="itembox" :class="index >= 3? 'dongtai' : ''">
                    <img src="../../../assets/img/delivery-lan.png" v-if="index >= 3">
                    <img src="../../../assets/img/delivery-hui.png" v-else>
                    <span>3.{{ $t('buyer.myCart.deliveryInfo') }}</span>
                </div>
                <div class="itembox" :class="index >= 4? 'dongtai' : ''">
                    <img src="../../../assets/img/payment-lan.png" v-if="index >= 4">
                    <img src="../../../assets/img/payment-hui.png" v-else>
                    <span>4.{{ $t('buyer.myCart.payment') }}</span>
                </div>
                <div class="itembox" :class="index >= 5? 'dongtai' : ''">
                    <img src="../../../assets/img/confirmation-lan.png" v-if="index >= 5">
                    <img src="../../../assets/img/confirmation-hui.png" v-else>
                    <span>5.{{ $t('buyer.myCart.confirmation') }}</span>
                </div>
            </div>
            <div class="itembuz">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data(){
            return {
                index: 1,
            }
        },
        mounted() {
            this.getPath()
        },
        watch:{
            '$route':'getPath'
        },
        methods: {
            // 获取路由
            getPath(){
                switch(this.$route.path) {
                    case '/index/cart/mycart':
                    this.index = 1;
                    break;
                    case '/index/cart/shippinginfo':
                    this.index = 2;
                    break;
                    case '/index/cart/deliveryinfo':
                    this.index = 3;
                    break;
                    case '/index/cart/Payment':
                    this.index = 4;
                    break;
                    case '/index/cart/completeorder':
                    this.index = 5;
                    break;
                }
            }
        }
    }
</script>



<style lang="less" scoped>
    .phone {
        .allbox {
            width: 100%;
            .topliucheng {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 45px;
                .itembox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 20%;
                    font-size: 20px;
                    font-family: Arial;
                    font-weight: 400;
                    &>img {
                        width: 60px;
                        height: 60px;
                        margin-bottom: 15px;
                    }
                }
                .dongtai {
                    color: #00ADF1 !important;
                }
            }
        }
    }

</style>