<template>
	<!-- 手机端 -->
	<div class="phone">
		<div class="centbox">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import {buyerInfo,huihtongji} from '@/api/buyerapi'
	export default {
		data() {
			return {
				index: 0, //动态显示组件和图标
				activeIndex: '/',
				buyerInfo: {}, //买家信息
				count: '', //会话统计
			}
		},
		created(){
			this.setCurrentRoute()
		},
		mounted(){
			this.getbuyerInfo()
			this.gettongji()
		},
		watch: {
			$route(){
				this.setCurrentRoute()
			}
		},
		methods: {
			// 获取个人信息
			async getbuyerInfo(){
				const {data: res} = await buyerInfo()
				this.buyerInfo = res.success
				console.log(res)
			},
			async gettongji(){
				const {data: res} = await huihtongji()
				this.count = res.success.count
				// console.log('aaaaaaaa',res)
			},
			// 仪表盘
			toDashboard(){
				this.$router.push('/index/mypage/dashboard')
			},
			// 购买历史
			toHistory(){
				this.$router.push('/index/mypage/purchasehistory')
			},
			// 个人资料管理
			toManage(){
				this.$router.push('/index/mypage/manageprofile')
			},
			// 已发送退款请求
			toSent(){
				this.$router.push('/index/mypage/sentrefundrequest')
			},
			// 愿望清单
			toWishlist(){
				this.$router.push('/index/mypage/wishlist')
			},
			// 我的钱包
			toMyWallet() {
				this.$router.push('/index/mypage/mywallet')
			},
			// 工单
			toSupportTicket() {
				this.$router.push('/index/mypage/supportticket')
			},
			// 对话
			toConversations() {
				this.$router.push('/index/mypage/conversations')
			},
			// 邀请
			toBeaBrushingUser() {
				this.$router.push('/index/mypage/beabrushinguser')
			},
			// 监听当前路径
			setCurrentRoute() {
            	this.activeIndex = this.$route.path;    //监听到当前路由状态并激活当前菜单
				if(this.activeIndex == '/index/mypage/dashboard'){
					this.index = 1
				}else if(this.activeIndex == '/index/mypage/purchasehistory' || this.activeIndex == '/index/mypage/orderdetails') {
					this.index = 2
				}else if(this.activeIndex == '/index/mypage/sentrefundrequest'){
					this.index = 3
				}else if(this.activeIndex == '/index/mypage/wishlist'){
					this.index = 4
				}else if(this.activeIndex == '/index/mypage/conversations' || this.activeIndex == '/index/mypage/conversationswith'){
					this.index = 5
				}else if (this.activeIndex == '/index/mypage/mywallet') {
					this.index = 6
				} else if (this.activeIndex == '/index/mypage/supportticket') {
					this.index = 7
				}else if(this.activeIndex == '/index/mypage/manageprofile') {
					this.index = 8
				} else if (this.activeIndex == '/index/mypage/beabrushinguser') {
					this.index = 9
				}
				console.log(this.activeIndex)
        	}

		}
	}
</script>

<style scoped lang="less">
	.router-link-active {
		color: #00adf1;
		text-decoration: none;
	}
	a {
		text-decoration: none;
		color: #000;
	}
	.phone {
		.centbox {
			width: 100%;
		}
	}
</style>
