import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import VueClipboard from "vue-clipboard2"
import "element-plus/dist/index.css"
import {createI18n} from 'vue-i18n'
import 'amfe-flexible'
import '@/assets/css/style.css'


const i18n = createI18n({
    locale: sessionStorage.getItem('language')? sessionStorage.getItem('language'): 'en',
    fallbackLocale: 'en',
    messages: {
        en: require('@/locales/en'),
        hk: require('@/locales/hk'),
        de: require('@/locales/de'),
        jp: require('@/locales/jp'),
        kr: require('@/locales/kr'),
        sa: require('@/locales/sa')
    },
    legacy: false
})


createApp(App).use(store).use(ElementPlus).use(router).use(VueClipboard).use(i18n).mount('#app')
