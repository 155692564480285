<template>
	<div class="phone">
		<div class="managebox">
			<div class="title">
				<text>{{ $t('buyer.manageProfile.manageProfile') }}</text>
			</div>
			<div class="onebox">
				<div class="purchase">
					<text>{{ $t('seller.appliedRefundRequest.basicInfo') }}</text>
				</div>
				<div class="iteminp">
					<div class="leftbt">
						<text>{{ $t('buyer.manageProfile.fullName') }}：</text>
					</div>
					<div class="ybinp">
						<input type="text" :placeholder="$t('buyer.manageProfile.fullName')" v-model="name">
					</div>
				</div>
				<div class="iteminp">
					<div class="leftbt">
						<text>{{ $t('buyer.manageProfile.phone') }}：</text>
					</div>
					<div class="ybinp">
						<input type="text" :placeholder="$t('buyer.manageProfile.phone')" v-model="phone">
					</div>
				</div>
				<el-upload
					v-model:file-list="head"
					class="upload-demo"
					:action="uploadURL"
					:on-success="uploadSuccess"
					:on-error="uploadError"
					:limit="1"
					list-type="picture"
				>
					<div class="iteminp">
						<div class="leftbt">
							<text>{{ $t('buyer.supportTicket.photo') }}：</text>
						</div>
						<div class="ybinp">
							<span class="span1">Browse {{ $t('seller.addYourProduct.chooseImage') }}</span>
						</div>
					</div>
				</el-upload>
				<div class="iteminp">
					<div class="leftbt">
						<text>{{ $t('buyer.manageProfile.password') }}：</text>
					</div>
					<div class="ybinp">
						<input type="password" v-model="password" :placeholder="$t('buyer.manageProfile.password')">
					</div>
				</div>
				<div class="iteminp">
					<div class="leftbt">
						<text>{{ $t('buyer.manageProfile.confirmPassword') }}：</text>
					</div>
					<div class="ybinp">
						<input type="password" v-model="passwordtwo" :placeholder="$t('buyer.manageProfile.confirmPassword')">
					</div>
				</div>
				<div class="updates" @click="editInfo">
					<text>{{ $t('buyer.manageProfile.updateProfile') }}</text>
				</div>
			</div>
			<div class="twobox">
				<div class="address">
					<text>{{ $t('buyer.addAddress.address') }}</text>
				</div>
				<div class="dizhi">
					<div class="twodizhi" v-for="item in addressList" :key="item.id">
						<div class="zcwenzi">
							<div class="itemdz">
								<text>{{ $t('buyer.addAddress.address') }}:</text>
								<text>{{ item.address }}</text>
							</div>
							<div class="itemdz">
								<text>{{ $t('buyer.addAddress.postcode') }}:</text>
								<text>{{ item.postal_code }}</text>
							</div>
							<div class="itemdz">
								<text>{{ $t('buyer.addAddress.city') }}:</text>
								<text>{{ item.city }}</text>
							</div>
							<div class="itemdz">
								<text>{{ $t('buyer.addAddress.stateProvince') }}:</text>
								<text>{{ item.state }}</text>
							</div>
							<div class="itemdz">
								<text>{{ $t('buyer.addAddress.nation') }}:</text>
								<text>{{ item.country }}</text>
							</div>
							<div class="itemdz">
								<text>{{ $t('buyer.addAddress.phone') }}:</text>
								<text>{{ item.phone }}</text>
							</div>
						</div>
						<div class="ycxz">
							<text @click="clickChange(item.id)">...</text>
							<text v-if="item.is_default == 1">Default</text>
						</div>
						<div class="dztc" v-if="dzshow == item.id">
							<span @click="showEdtiAddress(item)">{{ $t('buyer.addressManagement.edit') }}</span>
							<span v-if="item.is_default == 2" @click="setDefaultAddress(item.id)">{{ $t('buyer.addAddress.setAsDefault') }}</span>
							<span @click="delAddress(item.id)">{{ $t('buyer.addressManagement.delete') }}</span>
						</div>
					</div>
				</div>
				<div class="addbox" @click="tcshow = true">
					<text>{{ $t('buyer.addAddress.addAddress') }}</text>
				</div>
			</div>
			<!-- 添加地址弹窗 -->
			<div class="zhezhao" v-if="tcshow">
				<div class="tcbox">
					<div class="newAddress">
						<span>{{ $t('buyer.addAddress.addAddress') }}</span>
						<img src="../../assets/img/cuowu-hui.png" @click="gbTanchuang">
					</div>
					<div class="addressbox">
						<span>{{ $t('buyer.addAddress.address') }}</span>
						<textarea v-model="address"></textarea>
					</div>
					<div class="countrybox">
						<span>{{ $t('buyer.addAddress.nation') }}</span>
						<input type="text" v-model="country">
					</div>
					<div class="countrybox">
						<span>{{ $t('buyer.addAddress.stateProvince') }}</span>
						<input type="text" v-model="state">
					</div>
					<div class="countrybox">
						<span>{{ $t('buyer.addAddress.city') }}</span>
						<input type="text" v-model="city">
					</div>
					<div class="posbox">
						<span>{{ $t('buyer.addAddress.postcode') }}</span>
						<input type="text" v-model="postalCode">
					</div>
					<div class="posbox">
						<span>{{ $t('buyer.addAddress.phone') }}</span>
						<input type="text" v-model="phone">
					</div>
					<div class="savebox" @click="addAddress">
						<span>{{ $t('buyer.addAddress.save') }}</span>
					</div>
				</div>
			</div>
			<!-- 修改地址弹窗 -->
			<div class="zhezhao" v-if="tcshow2">
				<div class="tcbox">
					<div class="newAddress">
						<span>{{ $t('buyer.addAddress.addAddress') }}</span>
						<img src="../../assets/img/cuowu-hui.png" @click="gbTanchuang">
					</div>
					<div class="addressbox">
						<span>{{ $t('buyer.addAddress.address') }}</span>
						<textarea name="" id="" cols="30" rows="10" v-model="address"></textarea>
					</div>
					<div class="countrybox">
						<span>{{ $t('buyer.addAddress.nation') }}</span>
						<input type="text" v-model="country">
					</div>
					<div class="countrybox">
						<span>{{ $t('buyer.addAddress.stateProvince') }}</span>
						<input type="text" v-model="state">
					</div>
					<div class="countrybox">
						<span>{{ $t('buyer.addAddress.city') }}</span>
						<input type="text" v-model="city">
					</div>
					<div class="posbox">
						<span>{{ $t('buyer.addAddress.postcode') }}</span>
						<input type="text" v-model="postalCode">
					</div>
					<div class="posbox">
						<span>{{ $t('buyer.addAddress.phone') }}</span>
						<input type="text" v-model="phone">
					</div>
					<div class="savebox" @click="editAddress">
						<span>{{ $t('buyer.addAddress.save') }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ElMessage } from 'element-plus'
	import {uploadURL} from '@/api/api'
	import {addressList,addressAdd,setDefault,addressDel,addressEdit,buyerInfoEidt,buyerInfo} from '@/api/buyerapi'
	export default {
		data(){
			return {
				name: '',
				phone: '',
				head: [],
				password: '',
				passwordtwo: '',
				uploadURL: uploadURL, //上传地址
				tcshow: false,	//编辑地址弹窗
				tcshow2: false, //修改地址弹窗
				dzshow: -1,
				address: '', //地址
                country: '', //国家
                state: '', //州
                city: '',//城市
                postalCode: '', //邮编
                phone: '', //电话
                addressList: [], //地址列表
				lsid: '', //编辑地址时用的ID
				uploadHead: '', //上传时的图片本地路径
			}
		},
		mounted(){
			this.getAddress()
			this.getbuyerInfo()
		},
		methods: {
			// 获取买家个人资料
			async getbuyerInfo(){
				const {data: res} = await buyerInfo()
				if(res.status == 200) {
					this.name = res.success.member_name
					this.phone = res.success.member_phone
					if(res.success.member_head == '') {
						this.head = []
					}else {
						this.head = [{url: res.success.member_head}]
					}
				}
			},
			// 修改买家个人资料
			async editInfo(){
				if(this.name == '') {return ElMessage.error('Data cannot be empty')}
				if(this.phone == '') {return ElMessage.error('Data cannot be empty')}
				if(this.password !== this.passwordtwo) {return ElMessage.error('Password inconsistency')}
				const {data: res} = await buyerInfoEidt({
					member_name: this.name, 
					member_phone: this.phone, 
					member_head: this.uploadHead, 
					member_pwd: this.password, 
					member_pwd_two: this.passwordtwo
				})
				if(res.status == 200) {
					this.$router.go(0)
					ElMessage.success(res.message)
				}else {
					ElMessage.error(rers.message)
				}
				console.log(res)
			},
			// 获取地址列表
			async getAddress(){
				const {data: res} = await addressList()
				this.addressList = res.success
			},
			// 添加地址
            async addAddress(){
				if(this.address == '') {return ElMessage.error('Please enter the address')}
                if(this.country == '') {return ElMessage.error('Please enter the address')}
                if(this.state == '') {return ElMessage.error('Please enter the address')}
                if(this.city == '') {return ElMessage.error('Please enter the address')}
                if(this.phone == '') {return ElMessage.error('Please enter your phone number')}
                const {data: res} = await addressAdd({
                    address: this.address, //地址(Address)
                    country: this.country, //国家(Country)
                    state: this.state, //洲(State)
                    city: this.city, //市(City)
                    postal_code: this.postalCode, //邮编(Postal code)
                    phone: this.phone //电话(Phone)
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
					this.getAddress()
                    this.tcshow = false
                    this.address = ''
                    this.country = ''
                    this.state = ''
                    this.city = ''
                    this.postalCode = ''
                    this.phone = ''
                }else {
                    ElMessage.error(res.message)
                }
            },
			// 显示修改地址弹窗
			showEdtiAddress(item){
				this.dzshow = -1
				this.tcshow2 = true
				this.address = item.address
				this.country = item.country
				this.state = item.state
				this.city = item.city
				this.postalCode = item.postal_code
				this.phone = item.phone
				this.lsid = item.id
			},
			// 修改地址
			async editAddress(){
				const {data: res} = await addressEdit({
					id: this.lsid,
					address: this.address, //地址(Address)
                    country: this.country, //国家(Country)
                    state: this.state, //洲(State)
                    city: this.city, //市(City)
                    postal_code: this.postalCode, //邮编(Postal code)
                    phone: this.phone //电话(Phone)
				})
				if(res.status == 200) {
                    ElMessage.success(res.message)
					this.getAddress()
                    this.tcshow2 = false
                    this.address = ''
                    this.country = ''
                    this.state = ''
                    this.city = ''
                    this.postalCode = ''
                    this.phone = ''
                }else {
                    ElMessage.error(res.message)
                }
			},
			// 设为默认地址
			async setDefaultAddress(id){
				this.dzshow = -1
				const {data: res} = await setDefault({
					id: id
				})
				if(res.status == 200) {
					ElMessage.success(res.message)
					this.getAddress()
				}else {
					ElMessage.error(res.message)
				}
			},
			// 删除地址
			async delAddress(id){
				this.dzshow = -1
				const {data: res} = await addressDel({
					id: id
				})
				if(res.status == 200) {
					ElMessage.success(res.message)
					this.getAddress()
				}else {
					ElMessage.error(res.message)
				}
			},
			// 显示地址操作
			clickChange(id){
				this.dzshow = this.dzshow == id ? -1:id
			},
            // 关闭弹出
            gbTanchuang(){
                this.tcshow = false
                this.address = ''
                this.country = ''
                this.state = ''
                this.city = ''
                this.postalCode = ''
                this.phone = ''
            },
			// 图片上传失败
            uploadError(){
                ElMessage.warning('Upload failed')
            },
            // 图片上传成功
            uploadSuccess(e){
                ElMessage.success('Uploaded successfully')
                this.uploadHead = e.success.file_url
            },
		}
	}
</script>

<style scoped lang="less">
	.phone {
		.managebox {
			width: 96%;
			margin: auto;
			overflow: hidden;
			.title {
				font-size: 24px;
				font-family: Arial;
				font-weight: bold;
				color: #000000;
				line-height: 100px;
				text-align: left;
			}
			.onebox {
				background-color: #fff;
				width: 100%;
				box-sizing: border-box;
				padding: 0 20px;
				padding-bottom: 30px;
				.purchase {
					font-size: 24px;
					font-family: Arial;
					font-weight: 400;
					color: #000000;
					line-height: 80px;
					width: 100%;
					border-bottom: solid 2px #CACACA;
					text-align: left;
				}
				.upload-demo{
					width: 100%;
					display: flex;
					flex-direction: column;
					align-content: center;
					justify-content: space-between;
					.iteminp {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 15px;
						width: 100%;
						.leftbt {
							font-size: 24px;
							font-family: Arial;
							font-weight: 400;
							color: #000000;
							line-height: 40px;
						}
						.ybinp {
							width: 80%;
							height: 80px;
							background: #F6F6F6;
							border-radius: 5px;
							font-size: 24px;
							.span1 {
								color: #757575;
								box-sizing: border-box;
								padding-left: 20px;
								display: inline-block;
								width: 100%;
								height: 80px;
								line-height: 80px;
								text-align: left;
							}
						}
					}
				}
				.iteminp {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 15px;
					.leftbt {
						font-size: 24px;
						font-family: Arial;
						font-weight: 400;
						color: #000000;
						line-height: 40px;
					}
					.ybinp {
						width: 80%;
						height: 80px;
						background: #F6F6F6;
						border-radius: 5px;
						font-size: 24px;
						input {
							width: 80%;
							height: 80px;
							box-sizing: border-box;
							padding-left: 35px;
							font-size: 24px;
							border: none;
							outline: none;
							background: #f6f6f6;
							text-align: left;
						}
					}
				}
				.updates {
					width: 300px;
					height: 70px;
					background: #00ADF1;
					border-radius: 5px;
					text-align: center;
					color: #fff;
					font-size: 24px;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 70px;
					margin: auto;
					margin-top: 35px;
					cursor: pointer;
				}
			}
			.twobox {
				background-color: #fff;
				width: 100%;
				box-sizing: border-box;
				padding: 0 30px;
				padding-bottom: 30px;
				margin-top: 25px;
				.address {
					font-size: 24px;
					font-family: Arial;
					font-weight: 400;
					color: #000000;
					line-height: 80px;
					width: 100%;
					border-radius: 5px;
					border-bottom: solid 2px #CACACA;
				}
				.dizhi {
					width: 100%;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					.twodizhi {
						width: 100%;
						box-sizing: border-box;
						padding: 20px;
						display: flex;
						justify-content: space-between;
						margin-top: 25px;
						position: relative;
						border-radius: 5px;
						border: solid 1px #969696;
						.zcwenzi {
							min-width: 320px;
							.itemdz {
								font-size: 24px;
								font-family: Arial;
								font-weight: 400;
								line-height: 50px;
								display: flex;
								align-items: center;
								&>text:nth-child(1){
									color: #969696;
								}
								&>text:nth-child(2){
									display: inline-block;
									max-width: 76%;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									margin-left: 5px;
								}
							}
						}
						.ycxz {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: flex-end;
							&>text:nth-child(1) {
								font-size: 30px;
								color: #00ADF1;
								margin-top: -10px;
								cursor: pointer;
							}
							&>text:nth-child(2){
								display: block;
								width: 105px;
								height: 40px;
								background: #00ADF1;
								border-radius: 5px;
								text-align: center;
								font-size: 18px;
								font-family: Arial;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 40px;
								margin-bottom: 15px;
							}
						}
						.dztc {
							position: absolute;
							width: 30%;
							border: solid 2px #969696;
							top: 60px;
							right: 40px;
							display: flex;
							flex-direction: column;
							align-items: center;
							span {
								width: 100%;
								line-height: 50px;
								text-align: left;
								box-sizing: border-box;
								padding-left: 20px;
								font-size: 24px;
							}
							span:hover {
								background: #00ADF1;
								color: #fff;
							}
						}
					}
				}
				.addbox {
					width: 300px;
					height: 70px;
					background: #00ADF1;
					border-radius: 5px;
					margin: auto;
					margin-top: 20px;
					font-size: 24px;
					font-family: Arial;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
					line-height: 70px;
					cursor: pointer;
				}
			}
			.zhezhao {
				position: fixed;
				z-index: 999;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.5);
				.tcbox {
					width: 80%;
					padding-bottom: 30px;
					background: #FFFFFF;
					border-radius: 5px;
					margin: auto;
					margin-top: 20%;
					.newAddress {
						width: 100%;
						height: 80px;
						font-size: 24px;
						font-family: Arial;
						font-weight: bold;
						color: #000000;
						box-sizing: border-box;
						padding: 0 30px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border: solid 1px #EDEDED;
						img {
							width: 30px;
							height: 30px;
						}
					}
					.addressbox {
						font-size: 24px;
						font-family: Arial;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						padding: 0 30px;
						margin-top: 20px;
						textarea {
							width: 80%;
							height: 120px;
							border: 2px solid #EDEDED;
							border-radius: 5px;
							outline: none;
							resize: none;
							box-sizing: border-box;
							padding: 10px;
							font-size: 24px;
						}
					}
					.countrybox {
						font-size: 24px;
						font-family: Arial;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						padding: 0 30px;
						margin-top: 20px;
						input {
							width: 80%;
							height: 80px;
							border: 2px solid #EDEDED;
							border-radius: 5px;
							font-size: 24px;
							box-sizing: border-box;
							padding: 0 15px;
							outline: none;
						}
					}
					.posbox {
						font-size: 24px;
						font-family: Arial;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						padding: 0 30px;
						margin-top: 20px;
						input {
							width: 80%;
							height: 80px;
							border: 2px solid #EDEDED;
							border-radius: 5px;
							font-size: 24px;
							box-sizing: border-box;
							padding: 0 15px;
							outline: none;
						}
					}
					.savebox {
						width: 100%;
						box-sizing: border-box;
						padding: 0 30px;
						display: flex;
						justify-content: flex-end;
						margin-top: 20px;
						span {
							display: inline-block;
							width: 180px;
							height: 70px;
							background: #00ADF1;
							border-radius: 5px;
							text-align: center;
							line-height: 70px;
							font-size: 24px;
							font-family: Arial;
							color: #FFFFFF;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>