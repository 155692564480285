<template>
    <div class="phone">
        <div class="bigbox">
            <div class="titles">
                <span>{{ $t('seller.home.myLoan') }}</span>
            </div>
            <div class="liubox">
                <div class="itemqb bgcolor1">
                    <img src="../../../assets/img/seller-money-slan.png" alt="">
                    <div class="qbhezi">
                        <span>{{ loanTotal }}</span>
                        <span>{{ $t('seller.myLoan.totalLoanQuota') }}</span>
                    </div>
                </div>
                <div class="itemqb bgcolor2">
                    <img src="../../../assets/img/seller-money-zi.png" alt="">
                    <div class="qbhezi">
                        <span>{{ loanAble }}</span>
                        <span>{{ $t('seller.myLoan.totalLoanAbleQuota') }}</span>
                    </div>
                </div>
                <div class="itemqb bgcolor3">
                    <img src="../../../assets/img/seller-money-lan.png" alt="">
                    <div class="qbhezi">
                        <span>{{ loanUsed }}</span>
                        <span>{{ $t('seller.myLoan.currentTotalBorrowing') }}</span>
                    </div>
                </div>
                <div class="itemqb bgcolor4">
                    <img src="../../../assets/img/seller-money-cheng.png" alt="">
                    <div class="qbhezi">
                        <span>{{ loanOutstanding }}</span>
                        <span>{{ $t('seller.myLoan.currentTotalLoanBorrowing') }}</span>
                    </div>
                </div>
                <div class="itemqbs" @click="toRequestForm">
                    <span>+</span>
                    <span>{{ $t('seller.myLoan.requestMoreQuota') }}</span>
                </div>
                <div class="itemqbs" @click="tcshow = true">
                    <span>+</span>
                    <span>{{ $t('seller.myLoan.applyForLoan') }}</span>
                </div>
            </div>
            <div class="tabboxs">
                <div class="loan">
                    <span>{{ $t('seller.myLoan.loanHistory') }}</span>
                </div>
                <div class="tableitem">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="loan_amount" :label="$t('seller.myLoan.loanAmount')" width="170" />
                        <el-table-column prop="outstanding_money" :label="$t('seller.myLoan.outstandingMoney')" width="180" />
                        <el-table-column prop="monthly_interest_rate" :label="$t('seller.myLoan.monthRate')" width="120" />
                        <el-table-column prop="months" :label="$t('seller.myLoan.months')" width="120" />
                        <el-table-column prop="start_time" :label="$t('seller.myLoan.startTime')" width="180" />
                        <el-table-column prop="end_time" :label="$t('seller.myLoan.endTime')" width="180" />
                        <el-table-column prop="complete_time" :label="$t('seller.myLoan.completeTime')" width="180" />
                        <el-table-column prop="created_at" :label="$t('seller.myLoan.date')" width="220" />
                        <el-table-column prop="u" :label="$t('seller.myLoan.options')" width="140">
                            <template #default="scope">
                                <img src="../../../assets/img/history-yanjing.png" @click="toLoanDetail(scope.row.id)">
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    class="my-pagination"
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
            <!-- 弹窗 -->
            <div class="zhezhao" v-if="tcshow">
                <div class="applytc">
                    <div class="loanbt">
                        <span>{{ $t('seller.myLoan.applyForLoan') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbitc">
                    </div>
                    <div class="quotabox">
                        <div class="quota">
                            <div class="divbox">
                                <span>{{ $t('seller.myLoan.totalLoanAbleQuota') }}</span>
                            </div>
                            <input type="text" disabled v-model="loanAble">
                        </div>
                        <div class="quota">
                            <div class="divbox">
                                <span>{{ $t('seller.myLoan.monthRate') }}</span>
                                <span>*</span>
                            </div>
                            <input type="text" disabled v-model="monthlyInterestRate">
                        </div>
                        <div class="months">
                            <div class="divbox">
                                <span>{{ $t('seller.myLoan.months') }}</span>
                                <span>*</span>
                            </div>
                            <el-select v-model="selectValue" class="m-2" placeholder="Select" size="large" @change="selectChaneg">
                                <el-option
                                style="font-size: 20px;height: 60px;"
                                v-for="item in selectList"
                                :key="item.values"
                                :label="item.name"
                                :value="item.values"
                                />
                            </el-select>
                        </div>
                        <div class="quota">
                            <div class="divbox">
                                <span>{{ $t('seller.myLoan.loanAmount') }}</span>
                                <span>*</span>
                            </div>
                            <input type="number" v-model="money">
                        </div>
                        <div class="btnbox">
                            <span @click="guanbitc">{{ $t('buyer.cart.cancel') }}</span>
                            <span @click="submitDaikuan">{{ $t('seller.myLoan.applyForLoan') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {sellerLoanCount,loanApply,loanBill} from "@/api/sellerapi"
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                tcshow: false, //弹窗
                loanTotal: '',
                loanAble: '',
                loanUsed: '',
                loanOutstanding: '',
                monthlyInterestRate: '',
                money: '', //输入的金额
                selectList: [], //下拉选择列表
                selectValue: '', //选择的值
                count: 0,//总条数
                page: 1, //页码
                tableData: [
                    {q: '500', w: '100000',e: '3%',r: '12',t: '2023-06-24',y: '2024-06-24',i: '',o: '2023-06-24 14:59:25', u: require('../../../assets/img/history-yanjing.png')}
                ],
            }
        },
        mounted(){
            this.getDaikuan()
            this.getdaikaunLishi()
        },
        methods:{
            // 获取我的贷款金额
            async getDaikuan(){
                const {data: res} = await sellerLoanCount()
                if(res.status == 200) {
                    this.loanTotal = res.success.loan_total
                    this.loanAble = res.success.loan_able
                    this.loanUsed = res.success.loan_used
                    this.loanOutstanding = res.success.loan_outstanding
                    this.monthlyInterestRate = res.success.monthly_interest_rate
                    this.selectList = res.success.months_list
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 获取贷款历史
            async getdaikaunLishi(){
                const {data: res} = await loanBill({
                    page: this.page
                })
                if(res.status == 200) {
                    this.count = parseInt(res.success.count)
                    this.page = res.success.page
                    this.tableData = res.success.list
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 贷款申请提交
            async submitDaikuan(){
                let able = this.loanAble
                able = able.slice(1)
                able = parseFloat(able)
                if(this.selectValue == '') return ElMessage.error('No month selected')
                if(this.money == '') return ElMessage.error('Please enter the amount')
                if(this.money < 100) return ElMessage.error('The amount cannot be less than 100')
                if(this.money > able) return ElMessage.error('Your loan limit is insufficient')
                const {data: res} = await loanApply({
                    months: this.selectValue, //月份(下拉选项value值)
                    borrowing_money: this.money //借钱金额必须大于100切小于可用额度（loan_able）
                })
                this.tcshow = false
                this.money = ''
                this.selectValue = ''
                if(res.status == 200) {
                    this.getdaikaunLishi()
                    this.getDaikuan()
                    ElMessage.success(res.message)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 下拉选择事件
            selectChaneg(e){
                console.log(e)
                this.selectValue = e
            },
            // 查看贷款详情
            toLoanDetail(id){
                this.$router.push({path: '/Home/SellerLoanDetails', query: {id: id}})
            },
            // 去填写贷款申请表单
            toRequestForm(){
                this.$router.push('/Home/SellerLoanRequestForm')
            },
            // 关闭弹窗
            guanbitc(){
                this.tcshow = false
                this.money = ''
                this.selectValue = ''
            },
            // 上一页
            prevChange(e){
				this.page = e
                this.getdaikaunLishi()
			},
			// 下一页
			nextChange(e){
				this.page = e
                this.getdaikaunLishi()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
                this.getdaikaunLishi()
			}
        }
    }
</script>
<style lang="less" scoped>
    .phone {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 80px;
                font-size: 24px;
                font-weight: 600;
                text-align: left;
                line-height: 80px; 
            }
            .liubox {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .itemqb {
                    width: 100%;
                    height: 240px;
                    border: 2px solid #DFDFDF;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 20px;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                    .qbhezi {
                        color: #FFFFFF;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        margin-top: 10px;
                        span:nth-child(1){
                            font-size: 36px;
                            font-family: Arial;
                            font-weight: bold; 
                        }
                        span:nth-child(2){
                            font-size: 20px;
                            opacity: 0.7;
                        }
                    }
                }
                .bgcolor1{background: #00479D;}
                .bgcolor2{background: #8957A1;}
                .bgcolor3{background: #00ADF1;}
                .bgcolor4{background: #FAA149;}
                .itemqbs {
                    width: 100%;
                    height: 240px;
                    border: 3px solid #DFDFDF;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 20px;
                    span:nth-child(1){
                        width: 80px;
                        height: 80px;
                        background: #CACACA;
                        border-radius: 50%;
                        font-size: 50px;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 80px;
                    }
                    span:nth-child(2){
                        font-size: 24px;
                        margin-top: 35px;
                    }
                }
            }
            .tabboxs {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .loan {
                    width: 100%;
                    height: 60px;
                    text-align: left;
                    line-height: 60px;
                    font-size: 24px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 2px solid #DFDFDF;
                }
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 40px;
                        height: 40px;
                    }
                    :deep(.number) {
                        font-size: 24px !important;
                    }
                    :deep(.is-first) {
                        width: 40px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 24px !important;
                    }
                }
            }
            .zhezhao {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 99;
                background: rgba(0, 0, 0, 0.5);
                .applytc {
                    width: 80%;
                    height: 510px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 150px;
                    .loanbt {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: bold;
                        border-bottom: solid 1px #EDEDED;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .quotabox {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 30px;
                        .quota {
                            width: 100%;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 15px;
                            font-size: 20px;
                            .divbox {
                                text-align: left;
                                width: 100px;
                                span:nth-child(2){
                                    color: red;
                                }
                            }
                            input {
                                width: 70%;
                                height: 60px;
                                border: 3px solid #EDEDED;
                                border-radius: 5px;
                                outline: none;
                                box-sizing: border-box;
                                padding-left: 10px;
                                font-size: 20px;
                            }
                        }
                        .months {
                            width: 100%;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 15px;
                            .m-2 {
                                width: 70%;
                                font-size: 20px;
                            }
                            :deep(.el-input__wrapper){
                                width: 100%;
                                height: 60px;
                                font-size: 20px;
                            }
                            .divbox {
                                text-align: left;
                                width: 100px;
                                span:nth-child(2){
                                    color: red;
                                }
                            }
                        }
                        .btnbox {
                            width: 100%;
                            height: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            span {
                                width: 150px;
                                height: 60px;
                                border-radius: 4px;
                                font-size: 24px;
                                text-align: center;
                                line-height: 60px;
                                border: 2px solid #00ADF1;
                                cursor: pointer;
                            }
                            span:nth-child(1){
                                color: #00ADF1;
                                background: #fff;
                            }
                            span:nth-child(2){
                                background: #00ADF1;
                                color: #fff;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>