<template>
    <!-- 个人信息管理 -->
    <div class="phone">
        <div class="allbox">
            <div class="titles">
                <span>{{ $t('buyer.manageProfile.manageProfile') }}</span>
            </div>
            <div class="basicinfo">
                <div class="basic">
                    <span>{{ $t('seller.appliedRefundRequest.basicInfo') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('buyer.manageProfile.fullName') }}</span>
                        <input type="text" :placeholder="$t('buyer.manageProfile.fullName')" v-model="youName">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('buyer.manageProfile.phone') }}</span>
                        <input type="text" :placeholder="$t('buyer.manageProfile.phone')" v-model="youPhone">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('buyer.supportTicket.photo') }}</span>
                        <el-upload
                            v-model:file-list="fileList"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess"
                            :on-error="handleError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('buyer.manageProfile.password') }}</span>
                        <input type="password" :placeholder="$t('buyer.manageProfile.password')" v-model="youPassword">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('buyer.manageProfile.confirmPassword') }}</span>
                        <input type="password" :placeholder="$t('buyer.manageProfile.confirmPassword')" v-model="confirmPassword">
                    </div>
                </div>
            </div>
            <div class="basicinfo margtop">
                <div class="basic">
                    <span>{{ $t('seller.withdrawChannelStetting.withdrawChannelStetting') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.cryptWithdrawal') }}</span>
                        <div class="switchs">
                            <el-switch v-model="cryptoWithdraw" size="large"/>
                        </div>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.cashPayment') }}</span>
                        <div class="switchs">
                            <el-switch v-model="cashPayment" size="large"/>
                        </div>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.bankPay') }}</span>
                        <div class="switchs">
                            <el-switch v-model="bankPayment" size="large"/>
                        </div>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.bankName') }}</span>
                        <input type="text" placeholder="Bank Name" v-model="bankName">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.bankAccountName') }}</span>
                        <input type="text" placeholder="Bank Account Name" v-model="bankAccountName">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.bankAccount') }}</span>
                        <input type="text" placeholder="Bank Account Number" v-model="bankAccountNumber">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.withdrawChannelStetting.bankRouterNo') }}</span>
                        <input type="text" placeholder="Bank Routing Number" v-model="bankRoutingNumber">
                    </div>
                    <div class="savebox">
                        <span @click="mpSellerInfo">{{ $t('seller.withdrawChannelStetting.submit') }}</span>
                    </div>
                </div>
            </div>
            <div class="withdrawbox">
                <div class="widthdraw">
                    <span>{{ $t('seller.withdrawChannelStetting.cryptoWithdrawSetting') }}</span>
                </div>
                <div class="twobox">
                    <div class="itemtwo" v-for="(item,index) in withdrawSetting" :key="item.cws_id">
                        <div class="itemtext">
                            <span>{{ $t('seller.withdrawChannelStetting.cryptoWithdrawType') }}:</span>
                            <span>{{ item.crypto_withdraw_method }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('seller.withdrawChannelStetting.address') }}:</span>
                            <span>{{ item.address }}</span>
                        </div>
                        <span class="diandian" @click="witharawSetting(index)">...</span>
                        <!-- 弹窗 -->
                        <div class="tcone" v-if="paymentId === index">
                            <span @click="editChange(item)">{{ $t('buyer.addressManagement.edit') }}</span>
                            <span @click="delChange(item)">{{ $t('buyer.addressManagement.delete') }}</span>
                        </div>
                    </div>
                    <div class="itemtwos" @click="showone = true">
                        <div class="addbox">
                            <span>+</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('seller.withdrawChannelStetting.addNewCryptoWithdrawMethod') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="addressbox">
                <div class="addr">
                    <span>{{ $t('buyer.addAddress.address') }}</span>
                </div>
                <div class="onebox">
                    <div class="itemtwos" @click="showtwo = true" v-if="addressArrey.length == 0">
                        <div class="addbox">
                            <span>+</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.addAddress') }}</span>
                        </div>
                    </div>
                    <div class="itemtwo" v-else>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.address') }}:</span>
                            <span>{{ addressArrey.address }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.postcode') }}:</span>
                            <span>{{ addressArrey.postal_code }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.city') }}:</span>
                            <span>{{ addressArrey.city }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.stateProvince') }}:</span>
                            <span>{{ addressArrey.state }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.nation') }}:</span>
                            <span>{{ addressArrey.country }}</span>
                        </div>
                        <div class="itemtext">
                            <span>{{ $t('buyer.addAddress.phone') }}:</span>
                            <span>{{ addressArrey.phone }}</span>
                        </div>
                        <span class="diandian" @click="showAddress = !showAddress">...</span>
                        <!-- 弹窗 -->
                        <div class="tcone" v-if="showAddress">
                            <span @click="editAddress">{{ $t('buyer.addressManagement.edit') }}</span>
                            <span @click="delAddrerss">{{ $t('buyer.addressManagement.delete') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 弹窗二 -->
            <div class="zhezhaobox" v-if="showone">
                <div class="cryptotc">
                    <div class="title">
                        <span>{{ $t('seller.withdrawChannelStetting.addNewCryptoWithdrawMethod') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="gbshowone">
                    </div>
                    <div class="methbox">
                        <span>{{ $t('seller.sendWithdrawRequest.withdrawMethod') }}</span>
                        <el-select v-model="payValue" class="m-2" placeholder="Select Withdraw Methods" size="large" @change="optionChaneg">
                            <el-option
                            style="font-size: 24px;height: 60px;"
                            v-for="item in options"
                            :key="item.id"
                            :label="item.payment_name"
                            :value="item.payment_name"
                            />
                        </el-select>
                    </div>
                    <div class="dizhi">
                        <span>{{ $t('buyer.addAddress.address') }}</span>
                        <textarea placeholder="Your Crypto Withdraw Address" v-model="withdrawAddress"></textarea>
                    </div>
                    <div class="save" @click="saveData">
                        <span>{{ $t('seller.shopSetting.save') }}</span>
                    </div>
                </div>
            </div>
            <!-- 弹窗三 -->
            <div class="zhezhaos" v-if="showtwo">
                <div class="newaddrtc">
                    <div class="taitou">
                        <span>{{ $t('buyer.addAddress.addAddress') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="gbshowtwo">
                    </div>
                    <div class="dzbox">
                        <span>{{ $t('buyer.addAddress.address') }}</span>
                        <textarea v-model="address"></textarea>
                    </div>
                    <div class="codes">
                        <span>{{ $t('buyer.addAddress.nation') }}</span>
                        <input type="text" :placeholder="$t('buyer.addAddress.nation')" v-model="country">
                    </div>
                    <div class="codes">
                        <span>{{ $t('buyer.addAddress.stateProvince') }}</span>
                        <input type="text" :placeholder="$t('buyer.addAddress.stateProvince')" v-model="state">
                    </div>
                    <div class="codes">
                        <span>{{ $t('buyer.addAddress.city') }}</span>
                        <input type="text" :placeholder="$t('buyer.addAddress.city')" v-model="city">
                    </div>
                    <div class="codes">
                        <span>{{ $t('buyer.addAddress.postcode') }}</span>
                        <input type="text" :placeholder="$t('buyer.addAddress.postcode')" v-model="postalCode">
                    </div>
                    <div class="codes">
                        <span>{{ $t('buyer.addAddress.phone') }}</span>
                        <input type="text" :placeholder="$t('buyer.addAddress.phone')" v-model="phone">
                    </div>
                    <div class="save" @click="saveAddress">
                        <span>{{ $t('seller.shopSetting.save') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {sellerInfo,sellerMp,sellerCws,sellerDel,adderssSet,adderssDel} from '../../../api/sellerapi.js'
    import {uploadURL,paymentMethod} from '../../../api/api.js'
    import { ElMessage,ElMessageBox } from 'element-plus'
    export default {
        data () {
            return {
                showone: false, //弹窗
                showtwo: false, //弹窗三
                showAddress: false, //添加地址弹窗
                uploadURL: uploadURL, //图片上传URL
                youName: '', //用户名
                youPhone: '', //用户手机
                youPassword: '', //用户密码
                confirmPassword:'', //确认密码
                cryptoWithdraw: false, //加密货币提现
                cashPayment: false, //现金支付
                bankPayment: false, //银行支付
                bankName: '', //银行名称
                bankAccountName: '', //银行账户名称
                bankAccountNumber: '', //银行帐号
                bankRoutingNumber: '', //银行路由号
                imgurl: '', //上传成功的图片地址
                withdrawSetting: [], //加密提现设置
                options:[], //支付方式列表
                payValue: '', //支付方式的值
                payId: '', //支付的id
                cwsId: '', //加密提现项的id
                fileList: [] ,//头像列表
                paymentId: -1, //加密提现点击事件
                withdrawAddress: '', //加密地址
                addressArrey: [], //地址信息
                address: '', //地址信息
                country: '', //国家
                state: '', //州
                city: '', //城市
                postalCode: '', //邮编
                phone: '', //手机
            }
        },
        mounted(){
            this.getseller()
            this.getPayMethod()
        },
        methods:{
            // 获取信息,数据回显
            async getseller(){
                const {data: res} = await sellerInfo()
                if(res.status == 200) {
                    this.youName = res.success.basic_info.member_name
                    this.youPhone = res.success.basic_info.member_phone
                    if(res.success.basic_info.member_head || res.success.basic_info.member_head != '') {
                        this.fileList = [{url: res.success.basic_info.member_head}]
                    }else {
                        this.fileList = []
                    }
                    if(res.success.withdraw_setting.crypto_withdraw == 1) {
                        this.cryptoWithdraw = true
                    }else {
                        this.cryptoWithdraw = false
                    }
                    if(res.success.withdraw_setting.cash_payment == 1) {
                        this.cashPayment = true
                    }else {
                        this.cashPayment = false
                    }
                    if(res.success.withdraw_setting.bank_payment == 1) {
                        this.bankPayment = true
                    }else {
                        this.bankPayment = false
                    }
                    this.bankName = res.success.withdraw_setting.bank_name
                    this.bankAccountName = res.success.withdraw_setting.bank_account_name
                    this.bankAccountNumber = res.success.withdraw_setting.bank_account_number
                    this.bankRoutingNumber = res.success.withdraw_setting.bank_routing_number
                    this.withdrawSetting = res.success.crypto_withdraw_setting
                    this.addressArrey = res.success.address
                }else {
                    ElMessage.error(res.message)
                }
                console.log('卖家信息',res)
            },
            // 修改个人数据
            async mpSellerInfo(){
                if(this.youName == '') {return ElMessage.error('Please enter a username')}
                if(this.youPhone == '') {return ElMessage.error('Please enter your phone number')}
                if(this.youPassword !== this.confirmPassword) {return ElMessage.error('The two passwords do not match')}
                if(this.fileList.length === 0) {return ElMessage.error('Please upload your avatar')}
                // if(this.bankName == '') {return ElMessage.error('Please enter the bank name')}
                // if(this.bankAccountName == '') {return ElMessage.error('Please enter the bank account name')}
                // if(this.bankAccountNumber == '') {return ElMessage.error('Please enter your bank account number')}
                // if(this.bankRoutingNumber == '') {return ElMessage.error('Please enter the bank routing number')}
                let crypto = ''
                let cashpayment = ''
                let bankpayment = ''
                if(this.cryptoWithdraw === true) {crypto = 1}else {crypto = 2}
                if(this.cashPayment === true) {cashpayment = 1}else {cashpayment = 2}
                if(this.bankPayment === true) {bankpayment = 1}else {bankpayment = 2}
                const {data: res} = await sellerMp({
                    member_name:  this.youName, //用户名称
                    member_phone: this.youPhone, //手机号
                    member_head: this.imgurl, //头像
                    member_pwd:  this.youPassword, //密码
                    crypto_withdraw: crypto, //加密货币提现(Crypto Withdraw):1开启,2关闭
                    cash_payment: cashpayment, //现金支付(Cash Payment):1开启,2关闭
                    bank_payment: bankpayment, //银行支付Bank Payment():1开启,2关闭
                    bank_name: this.bankName, //银行名(Bank Name)
                    bank_account_name: this.bankAccountName, //银行账户名称(Bank Account Name)
                    bank_account_number: this.bankAccountNumber, //银行帐号(Bank Account Number)
                    bank_routing_number: this.bankRoutingNumber //银行路由号码(Bank Routing Number)
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getseller()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 加密提现设置点击显示和隐藏
            witharawSetting(index){
                this.paymentId = this.paymentId == index? -1:index
            },
            // 加密提现编辑修改弹窗事件
            editChange(item){
                this.showone = true
                this.paymentId = -1
                this.withdrawAddress = item.address
                this.payValue = item.crypto_withdraw_method
                this.cwsId = item.cws_id
                const list = this.options
                for(var i = 0; i < list.length; i++){
                    if(this.payValue === list[i].payment_name) {
                        this.payId = list[i].id
                    }
                }
                console.log(item)
            },
            // 删除加密提现设置
            delChange(item){
                this.cwsId = item.cws_id
                this.showone = false
                this.paymentId = -1
                ElMessageBox.confirm(
                    'Are you sure to delete it?',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }).then(async ()=> {
                    const {data: res} = await sellerDel({
                        cws_id: this.cwsId
                    })
                    if(res.status == 200) {
                        ElMessage.success(res.message)
                        this.getseller()
                    } else {
                        ElMessage.error(res.message)
                    }
                }).catch(()=> {
                    ElMessage({
                        type: 'info',
                        message: 'Delete canceled',
                    })
                })
            },
            //加密提现设置编辑修改提交数据
            async saveData(){
                if(this.payId == '') return ElMessage.error('Please select payment method')
                if(this.withdrawAddress == '') return ElMessage.error('Please enter the address')
                const {data: res} = await sellerCws({
                    "cws_id": this.cwsId, //添加时为空，修改时必填
                    "payment_id": this.payId, //支付方式id
                    "address": this.withdrawAddress //地址
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getseller()
                }else {
                    ElMessage.error(res.message)
                }
                this.payId = ''
                this.withdrawAddress = ''
                this.payValue = ''
                this.cwsId = ''
                this.showone = false
            },
            // 显示编辑地址弹窗
            editAddress(){
                this.address = this.addressObj.address
                this.postalCode = this.addressObj.postal_code
                this.city = this.addressObj.city
                this.state = this.addressObj.state
                this.country = this.addressObj.country
                this.phone = this.addressObj.phone
                this.showtwo = true
                this.showAddress = false
            },
            // 保存地址添加修改
            async saveAddress(){
                if(this.address == '') return ElMessage.error('Please enter the address')
                if(this.postalCode == '') return ElMessage.error('Please enter the postal code')
                if(this.city == '') return ElMessage.error('Please enter the city')
                if(this.state == '') return ElMessage.error('Please enter the state')
                if(this.country == '') return ElMessage.error('Please enter the country')
                if(this.phone == '') return ElMessage.error('Please enter your phone number')
                const {data: res} = await adderssSet({
                    address: this.address, //地址(address)
                    postal_code: this.postalCode, //邮政编码(postal code)
                    city: this.city, //城市(city)
                    state: this.state, //州(state)
                    country: this.country, //国家(country)
                    phone: this.phone //电话(phone)
                })
                this.showtwo = false
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getseller()
                }else {
                    ElMessage.error(res.message)
                }
                this.address = ''
                this.postalCode = ''
                this.city = ''
                this.state = ''
                this.country = ''
                this.phone = ''
            },
            // 删除地址
            delAddrerss(){
                this.showAddress = false
                ElMessageBox.alert('Are you sure to delete it?',{
                    confirmButtonText: 'OK',
                    callback: async ()=> {
                        const {data: res} = await adderssDel()
                        if(res.status == 200) {
                            ElMessage.success(res.message)
                            this.getseller()
                        } else {
                            ElMessage.error(res.message)
                        }
                    } 
                })
            },
            // 下拉选择事件
            optionChaneg(){
                const list = this.options
                for(var i = 0; i < list.length; i++){
                    if(this.payValue === list[i].payment_name) {
                        this.payId = list[i].id
                    }
                }
            },
            // 图片上传失败
            handleError(){
                ElMessage.warning('Upload failed')
            },
            // 图片上传成功
            uploadSuccess(e){
                ElMessage.success('Uploaded successfully')
                this.imgurl = e.success.file_url
            },
            // 获取支付方式
            async getPayMethod(){
                const {data: res} = await paymentMethod()
                this.options = res.success
                console.log('支付方式',res)
            },
            // 弹窗
            gbshowone(){
                this.payId = ''
                this.withdrawAddress = ''
                this.payValue = ''
                this.cwsId = ''
                this.showone = false
            },
            gbshowtwo(){
                this.address = ''
                this.postalCode = ''
                this.city = ''
                this.state = ''
                this.country = ''
                this.phone = ''
                this.showtwo = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .phone {
        .allbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            .titles {
                width: 100%;
                height: 80px;
                text-align: left;
                line-height: 80px;
                font-size: 24px;
                font-weight: 600;
            }
            .margtop {
                margin-top: 40px;
            }
            .basicinfo {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 5px;
                .basic {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 3px solid #DFDFDF;
                    font-size: 24px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .shopbox {
                    box-sizing: border-box;
                    padding-left: 30px;
                    padding-bottom: 30px;
                    .itembox {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        box-sizing: border-box;
                        padding-right: 30px;
                        font-size: 20px;
                        .upload-demo {
                            width: 100%;
                        }
                        .switchs {
                            width: 100%;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-top: 10px;
                            :deep(.el-switch__core) {
                                height: 40px !important;
                                width: 80px !important;
                                border-radius: 20px;
                            }
                        }
                        input {
                            width: 100%;
                            height: 70px;
                            border: 2px solid #DFDFDF;
                            border-radius: 6px;
                            outline: none;
                            box-sizing: border-box;
                            padding-left: 15px;
                            font-size: 20px;
                            margin-top: 10px;
                        }
                        :deep(.el-upload) {
                            width: 100%;
                            height: 80px;
                            border: 2px solid #DFDFDF;
                            border-radius: 6px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-top: 10px;
                            span:nth-child(1){
                                width: 79px;
                                height: 80px;
                                line-height: 80px;
                                background: #DCDCDC;
                                border-radius: 5px;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                            }
                        }
                        textarea {
                            width: 1280px;
                            height: 109px;
                            background: #FFFFFF;
                            border: 2px solid #DFDFDF;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 24px;
                            resize: none;
                        }
                    }
                    .savebox {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 20px;
                        span {
                            width: 180px;
                            height: 60px;
                            background: #0277BD;
                            border-radius: 4px;
                            line-height: 60px;
                            text-align: center;
                            color: #fff;
                            font-size: 24px;
                            margin-right: 30px;
                        }
                    }
                }
            }
            .withdrawbox {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 40px;
                .widthdraw {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 2px solid #DFDFDF;
                    font-size: 24px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .twobox {
                    width: 100%;
                    min-height: 180px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    padding-bottom: 10px;
                    .itemtwo {
                        width: 80%;
                        height: 200px;
                        border: 2px solid #E5E5E5;
                        border-radius: 4px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 25px;
                        position: relative;
                        margin: 10px;
                        flex-shrink: 0;
                        .itemtext {
                            font-size: 20px;
                            margin: 5px 0;
                            span:nth-child(2){
                                color: #969696;
                                margin-left: 5px;
                            }
                        }
                        .diandian {
                            font-size: 35px;
                            font-weight: bold;
                            writing-mode: tb-rl;
                            position: absolute;
                            z-index: 9;
                            right: 0;
                            top: 10px;
                        }
                        .tcone {
                            width: 280px;
                            border: solid 2px #DCDCDC;
                            border-radius: 5px;
                            position: absolute;
                            top: 40px;
                            right: 30px;
                            background: #fff;
                            span {
                                display: block;
                                width: 100%;
                                height: 60px;
                                font-size: 20px;
                                line-height: 60px;
                                box-sizing: border-box;
                                padding-left: 10px;
                            }
                            span:hover{
                                background: #0277BD;
                                color: #fff;
                            }
                        }
                    }
                    .itemtwos {
                        width: 80%;
                        height: 200px;
                        border: 2px solid #E5E5E5;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .addbox {
                            font-size: 80px;
                        }
                        .itemtext {
                            font-size: 20px;
                            span:nth-child(2){
                                color: #969696;
                            }
                        }
                    }
                }
            }
            .addressbox {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 40px;
                margin-bottom: 40px;
                .addr {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 2px solid #DFDFDF;
                    font-size: 24px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .onebox {
                    width: 100%;
                    padding: 30px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .itemtwos {
                        width: 80%;
                        min-height: 200px;
                        border: 2px solid #E5E5E5;
                        border-radius: 4px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .addbox {
                            font-size: 80px;
                        }
                        .itemtext {
                            font-size: 20px;
                            span:nth-child(2){
                                color: #969696;
                            }
                        }
                    }
                    .itemtwo {
                        width: 80%;
                        min-height: 200px;
                        border: 1px solid #E5E5E5;
                        border-radius: 4px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        text-align: left;
                        box-sizing: border-box;
                        padding: 0 25px;
                        position: relative;
                        .itemtext {
                            font-size: 20px;
                            margin: 5px 0;
                            span:nth-child(2){
                                color: #969696;
                                margin-left: 5px;
                            }
                        }
                        .diandian {
                            font-size: 30px;
                            font-weight: bold;
                            writing-mode: tb-rl;
                            position: absolute;
                            z-index: 9;
                            right: 0;
                            top: 10px;
                            cursor: pointer;
                        }
                        .tcone {
                            width: 260px;
                            border: solid 1px #DCDCDC;
                            border-radius: 5px;
                            position: absolute;
                            top: 35px;
                            right: 30px;
                            background: #fff;
                            span {
                                display: block;
                                width: 100%;
                                height: 50px;
                                font-size: 20px;
                                line-height: 50px;
                                box-sizing: border-box;
                                padding-left: 10px;
                                cursor: pointer;
                            }
                            span:hover{
                                background: #0277BD;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .zhezhaobox {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: 999;
                .cryptotc {
                    width: 80%;
                    border: solid 1px #DCDCDC;
                    background: #fff;
                    margin: auto;
                    margin-top: 200px;
                    border-radius: 5px;
                    padding-bottom: 30px;
                    .title {
                        width: 100%;
                        height: 60px;
                        box-sizing: border-box;
                        padding: 0 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: solid 2px #DCDCDC;
                        font-size: 24px;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                        font-weight: 600;
                    }
                    .methbox {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 40px;
                        font-size: 20px;
                        .m-2 {
                            width: 70%;
                        }
                        :deep(.el-input__wrapper){
                            width: 100%;
                            height: 70px;
                            font-size: 20px;
                            border: solid 1px #DCDCDC;
                        }
                    }
                    .dizhi {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        font-size: 20px;
                        margin-top: 20px;
                        textarea {
                            width: 70%;
                            height: 100px;
                            font-size: 20px;
                            resize: none;
                            box-sizing: border-box;
                            border: solid 1px #DCDCDC;
                            padding: 10px;
                            outline: none;
                        }
                    }
                    .save {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        span {
                            width: 120px;
                            height: 60px;
                            border-radius: 5px;
                            background: #0277BD;
                            color: #fff;
                            font-size: 24px;
                            text-align: center;
                            line-height: 60px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .zhezhaos {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: 999;
                .newaddrtc {
                    width: 80%;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin: 0 auto;
                    margin-top: 150px;
                    padding-bottom: 20px;
                    .taitou {
                        width: 100%;
                        height: 70px;
                        box-sizing: border-box;
                        padding: 0 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: solid 1px #DCDCDC;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                        font-size: 24px;
                        font-weight: 600;
                    }
                    .dzbox {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        font-size: 20px;
                        margin-top: 20px;
                        textarea {
                            width: 70%;
                            height: 100px;
                            font-size: 20px;
                            resize: none;
                            box-sizing: border-box;
                            border: solid 1px #DCDCDC;
                            padding: 10px;
                            outline: none;
                        }
                    }
                    .codes {
                        width: 100%;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        font-size: 20px;
                        input {
                            height: 70px;
                            width: 70%;
                            outline: none;
                            border: solid 1px #DCDCDC;
                            box-sizing: border-box;
                            padding-left: 10px;
                            font-size: 20px;
                        }
                    }
                    .save {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-top: 20px;
                        span {
                            width: 120px;
                            height: 60px;
                            border-radius: 5px;
                            background: #0277BD;
                            color: #fff;
                            font-size: 24px;
                            text-align: center;
                            line-height: 60px;
                        }
                    }
                }
            }
        }
    }
</style>