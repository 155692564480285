<template>
    <div class="phone">
        <!-- 买家商品详情 -->
        <div class="allbox">
            <div class="zhanwei"></div>
            <div class="topshop">
                <div class="swiperbox">
                    <el-carousel :height="img_height +'px'" indicator-position="outside" :interval="10000" @change="img_load">
                        <el-carousel-item v-for="item in newimgarr" :key="item.img_url">
                            <img ref="imgHeight" :src="item.img_url"  @load="img_loads"/>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="rightmsbox">
                    <div class="btbox">
                        <div class="biaoti">
                            <span>{{ goodsName }}</span>
                        </div>
                        <div class="xxbox">
                            <el-rate v-model="xingxing" disabled />
                        </div>
                        <div class="mshui">
                            <span>Estimate Shioping Time 10Days</span>
                        </div>
                    </div>
                    <div class="mstwobox">
                        <div class="soldby">
                            <span>sold by:</span>
                            <span>NNancys shop</span>
                        </div>
                        <div class="messagebox">
                            <span>Message Buyer</span>
                        </div>
                    </div>
                    <div class="mssnabox">
                        <span>{{ $t('buyer.orderDetail.price') }}:</span>
                        <span>{{ primary }}</span>
                    </div>
                    <div class="sizebox" v-if="size != ''">
                        <span>Size:</span>
                        <div class="itmesiez">
                            <div v-for="(item,index) in size" :key="index" @click="xzSize(item)" :class="siezValue == item? 'xzstyle':'' ">
                                <span>{{ item }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="totalpic">
                        <span>{{ $t('buyer.orderDetail.totalOrderAmount') }}</span>
                        <span>{{ primary }}</span>
                    </div>
                    <div class="addto">
                        <span>Add to wishlist</span>
                    </div>
                    <div class="refund">
                        <span>{{ $t('agent.sellerOrder.refund') }}:</span>
                        <img src="../../assets/img/30days.png" alt="" />
                        <span class="spanview">View Policy</span>
                    </div>
                    <div class="share">
                        <span>Share:</span>
                        <div class="imgbox">
                            <img src="../../assets/img/01-youjian.png" alt="" />
                            <img src="../../assets/img/02-tuite.png" alt="" />
                            <img src="../../assets/img/03-facebook.png" alt="" />
                            <img src="../../assets/img/04-in.png" alt="" />
                            <img src="../../assets/img/05-phone.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 底部 -->
            <div class="bombox">
                <div class="bomright">
                    <div class="righttop">
                        <div class="description">
                            <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                        </div>
                        <div class="msbox">
                            <span>{{ details }}</span>
                        </div>
                    </div>
                    <div class="rightbom">
                        <div class="newproducts">
                            <span>Related products</span>
                        </div>
                        <div class="shopboxs">
                            <div class="shopitembox" ref="shopone">
                                <div class="scorll_list">
                                    <div class="shopitem" v-for="item in xgGodds" :key="item.id" @click="getShopdetail(item.id)">
                                        <img :src="item.main_img" />
                                        <div class="jiage">
                                            <span>{{ item.primary }}</span>
                                            <el-rate v-model="item.goods_rating" disabled />
                                            <div class="mswenz">{{ item.goods_name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="jiantbox">
                                <img src="../../assets/img/zjiant.png" @click="leftSlide" />
                                <img src="../../assets/img/yjiant.png" @click="rightSlide" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bomleft">
                    <div class="newproducts">
                        <span>Top Selling Products</span>
                    </div>
                    <div class="bomleftshop">
                        <div class="bomleftitem" v-for="item in cxGodds" :key="item.id" @click="getShopdetail(item.id)">
                            <div class="imgboxs">
                                <img :src="item.main_img" />
                            </div>
                            <div class="jiage">
                                <div class="mswenz">{{ item.goods_name }}</div>
                                <el-rate v-model="item.goods_rating" disabled />
                                <span>{{ item.primary }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="zhezhao" v-if="shows">
            <div class="centboxs">
                <img class="xximg" src="../../assets/img/delete-bai.png" @click="shows = false">
                <div class="cgbox">
                    <img src="../../assets/img/success-lv.png">
                    <span>Item added to your cart!</span>
                </div>
                <div class="btnbox">
                    <span @click="shows = false">Back to shopping</span>
                    <span @click="toCarts">Froceed to Checkout</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UseSwiper from '../../components/UseSwiper.vue'
import {goodsInfo,goodscx,goodsxg} from '@/api/buyerapi'
export default {
    components: {
        UseSwiper,
    },
    data() {
        return {
            timer: null, //定时器，方便清除
            goodsName: '', //商品名称
            primary: '', //价格
            details: '', //详情
            size: [], //规格大小
            shopnum: 1, //商品数量
            xingxing: 0, //评分
            shows: false,
            id: '', //商品id
            imgArrer: [],
            newimgarr: [], //
            cxGodds: [], //畅销产品
            xgGodds: [], //相关产品
            siezValue: '', //选择的规格的值
            img_height: 1, //手机端轮播图高度
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.getGoodDetail()
        this.getCxgoods()
        this.getxGgoods()
    },
    methods: {
        // 左箭头切换
        leftSlide() {
            let left = this.$refs.shopone.scrollLeft
            let num = 0
            clearInterval(this.timer)
            this.timer = null
            this.timer = setInterval(() => {
                if (!left || num >= 197) {
                    clearInterval(this.timer)
                    this.timer = null
                    return
                }
                this.$refs.shopone.scrollLeft = left -= 21.5
                // 保存向左滚动距离（方便判断一次性滚动多少距离）
                num += 21.5
            }, 25)
        },
        // 右箭头切换
        rightSlide() {
            let left = this.$refs.shopone.scrollLeft
            // 保存元素的整体宽度
            let scrollWidth = this.$refs.shopone.scrollWidth
            // 保存元素的可见宽度
            let clientWidth = this.$refs.shopone.clientWidth
            let num = 0
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                // num>=300一次性滚动距离
                if (left + clientWidth >= scrollWidth || num >= 197) {
                    clearInterval(this.timer)
                    return
                }
                // 给滚动盒子元素赋值向左滚动距离
                this.$refs.shopone.scrollLeft = left += 21.5
                // 保存向左滚动距离（方便判断一次性滚动多少距离）
                num += 21.5
            }, 25)
        },
        // 获取商品详情
        async getGoodDetail(){
            const {data: res}= await goodsInfo({
                id: this.id
            })
            if(res.status == 200) {
                this.goodsName = res.success.goods_name
                this.primary = res.success.primary
                this.imgArrer = res.success.imgs
                this.newimgarr = this.imgArrer.map(item => ({
                    "img_url": item
                }))
                console.log('aaaaaaaaaaaaaaa',this.newimgarr)
                this.details = res.success.details
                this.xingxing = Number(res.success.grade)
                this.size = res.success.size
            }
            // console.log('aaaaaaaaaaaaa',res)
        },
        // 畅销产品
        async getCxgoods(){
            const {data: res} = await goodscx({
                id: this.id
            })
            if(res.status == 200) {
                this.cxGodds = res.success
            }
            // console.log(res)
        },
        // 相关产品
        async getxGgoods(){
            const {data: res} = await goodsxg({
                id: this.id
            })
            if(res.status == 200) {
                this.xgGodds = res.success.list
            }
            // console.log(res)
        },
        getShopdetail(id) {
            this.id = id
            this.getGoodDetail()
        },
        img_load(e){
			this.img_height = this.$refs.imgHeight[e].height
		},
        img_loads(){
			this.img_height = this.$refs.imgHeight[0].height
		},
    },
}
</script>

<style lang="less" scoped>
    .phone {
        .allbox {
            width: 96%;
            margin: auto;
            margin-top: 30px;
            box-sizing: border-box;

            .zhanwei {
                height: 15px;
                width: 100%;
                background: #fff;
            }

            .topshop {
                width: 100%;
                background: #fff;
                display: flex;
                flex-direction: column;
                .swiperbox {
                    width: 100%;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .rightmsbox {
                    width: 100%;
                    margin-left: 30px;

                    .btbox {
                        width: 100%;
                        height: 120px;
                        border-bottom: 2px solid #f0f0f0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;

                        .biaoti {
                            width: 96%;
                            overflow: hidden;
                            font-size: 30px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #000000;
                            line-height: 30px;
                            text-align: left;
                            -webkit-line-clamp: 2;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            box-sizing: border-box;
                            padding-right: 20px;
                        }

                        .xxbox {
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #969696;
                        }

                        .mshui {
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #969696;
                        }
                    }
                }

                .mstwobox {
                    width: 100%;
                    height: 70px;
                    border-bottom: 2px solid #f0f0f0;
                    display: flex;
                    align-items: center;

                    .soldby {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        width: 200px;

                        span:nth-child(1) {
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #969696;
                            line-height: 20px;
                        }

                        span:nth-child(2) {
                            font-size: 26px;
                        }
                    }

                    .messagebox {
                        padding: 0 25px;
                        height: 34px;
                        background: #d9f3fd;
                        border-radius: 5px;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #00adf1;
                        line-height: 34px;
                    }
                }

                .mssnabox {
                    width: 100%;
                    height: 70px;
                    border-bottom: 2px solid #f0f0f0;
                    display: flex;
                    align-items: center;

                    span:nth-child(1) {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        line-height: 20px;
                        width: 200px;
                        text-align: left;
                    }

                    span:nth-child(2) {
                        font-size: 30px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #04adf1;
                    }
                }

                .sizebox {
                    width: 100%;
                    height: 70px;
                    border-bottom: 2px solid #f0f0f0;
                    display: flex;
                    align-items: center; 
                    &>span {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        line-height: 20px;
                        width: 200px;
                        text-align: left;
                    }
                    .itmesiez {
                        display: flex;
                        &>div {
                            width: 40px;
                            height: 40px;
                            border: 2px solid #B5B5B5;
                            border-radius: 4px;
                            font-size: 14px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #9A9A9A;
                            line-height: 40px;
                            margin-right: 5px;
                            cursor: pointer;
                        }
                        .xzstyle {
                            color: #00ADF1 !important;
                            border: 2px solid #00ADF1 !important;
                        }
                    }
                }
                .quantity {
                    width: 100%;
                    height: 70px;
                    border-bottom: 2px solid #f0f0f0;
                    display: flex;
                    align-items: center;

                    span:nth-child(1) {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        line-height: 20px;
                        width: 200px;
                        text-align: left;
                    }

                    .jiajianbox {
                        display: flex;
                        span {
                            display: inline-block;
                            cursor: pointer;
                        }

                        .span1,.span2 {
                            font-size: 30px;
                            width: 32px;
                            height: 32px;
                            background: #f2f3f8;
                            border-radius: 50%;
                            line-height: 32px;
                            text-align: center;
                        }

                        input {
                            width: 50px;
                            text-align: center;
                            height: 30px;
                            border: none;
                            outline: none;
                            font-size: 26px;
                            background: #fff;
                        }
                    }
                }

                .totalpic {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    align-items: center;

                    span:nth-child(1) {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        line-height: 20px;
                        width: 200px;
                        text-align: left;
                    }

                    span:nth-child(2) {
                        font-size: 30px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #04adf1;
                    }
                }

                .addBuy {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    .addcart,
                    .buynow {
                        width: 150px;
                        height: 40px;
                        border-radius: 5px;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        text-align: center;
                        line-height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img {
                            width: 15px;
                            height: 15px;
                            margin-bottom: 3px;
                            margin-right: 5px;
                        }
                    }

                    .addcart {
                        background: #d9f3fd;
                        color: #00adf1;
                        cursor: pointer;
                    }

                    .buynow {
                        background: #04adf1;
                        color: #fff;
                        margin-left: 20px;
                    }
                }

                .addto {
                    font-size: 24px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #04adf1;
                    display: flex;
                    margin-top: 35px;
                    cursor: pointer;
                }

                .refund {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    span:nth-child(1) {
                        display: inline-block;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        text-align: left;
                        width: 200px;
                    }

                    img {
                        width: 205px;
                        height: 37px;
                    }

                    .spanview {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #04adf1;
                    }
                }

                .share {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    margin-bottom: 30px;

                    span {
                        display: inline-block;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        text-align: left;
                        width: 200px;
                    }

                    .imgbox {
                        img {
                            width: 35px;
                            height: 32px;
                            margin: 0 5px;
                        }
                    }
                }
            }

            .bombox {
                width: 100%;
                margin-top: 45px;
                display: flex;
                flex-direction: column;

                .bomleft {
                    width: 100%;
                    background: #fff;

                    .newproducts {
                        width: 100%;
                        height: 60px;
                        font-size: 28px;
                        font-family: Arial;
                        box-sizing: border-box;
                        padding-left: 25px;
                        text-align: left;
                        line-height: 50px;
                        padding-top: 20px;
                    }

                    .bomleftshop {
                        width: 100%;
                        margin-top: 30px;
                        .bomleftitem {
                            height: auto;
                            border-top: solid 2px #f2f3f8;
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            box-sizing: border-box;
                            padding: 20px 10px;
                            margin-top: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .imgboxs {
                                width: 40%;
                                height: auto;
                                flex-shrink: 0;
                                padding: 10px;
                                img {
                                    width: 90%;
                                    height: auto;
                                }
                            }
                            .jiage {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: self-start;
                                .mswenz {
                                    width: 100%;
                                    height: 55px;
                                    font-size: 24px;
                                    font-family: Arial;
                                    color: #111111;
                                    text-align: left;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }

                                span {
                                    font-size: 28px;
                                    font-family: Arial;
                                    font-weight: bold;
                                    color: #53bef4;
                                }
                            }
                        }
                    }
                }

                .bomright {
                    width: 100%;
                    margin-top: 20px;
                    .righttop {
                        width: 100%;
                        min-height: 180px;
                        background: #fff;

                        .description {
                            font-size: 32px;
                            font-family: Arial;
                            width: 100%;
                            line-height: 100px;
                            text-align: left;
                            box-sizing: border-box;
                            padding-left: 25px;
                            border-bottom: solid 2px #f2f3f8;
                        }

                        .msbox {
                            width: 100%;
                            height: auto;
                            font-size: 14px;
                            font-family: Arial;
                            text-align: left;
                            box-sizing: border-box;
                            padding: 20px;
                        }
                    }

                    .rightbom {
                        width: 100%;
                        background: #fff;
                        margin-top: 15px;
                        .newproducts {
                            width: 100%;
                            border-bottom: solid 2px #f2f3f8;
                            line-height: 100px;
                            text-align: left;
                            font-size: 28px;
                            font-family: Arial;
                            box-sizing: border-box;
                            padding-left: 25px;
                        }

                        .shopboxs {
                            width: 100%;
                            position: relative;
                            padding: 25px 0px;

                            .shopitembox::-webkit-scrollbar {
                                display: none;
                            }

                            .shopitembox {
                                width: 100%;
                                overflow-x: scroll;

                                .scorll_list {
                                    display: flex;

                                    .shopitem {
                                        width: 190px;
                                        height: 310px;
                                        margin: 0 7px;
                                        background: #ffffff;
                                        border: 1px solid #f2f3f8;
                                        img {
                                            width: 170px;
                                            height: 190px;
                                            margin-top: 10px;
                                        }

                                        .jiage {
                                            width: 160px;
                                            margin-left: 15px;
                                            margin-top: 5px;

                                            span {
                                                font-size: 28px;
                                                font-family: Arial;
                                                font-weight: bold;
                                                color: #53bef4;
                                                line-height: 17px;
                                            }

                                            .mswenz {
                                                width: 100%;
                                                height: 30px;
                                                font-size: 24px;
                                                overflow: hidden;
                                                display: -webkit-box;
                                                -webkit-box-orient: vertical;
                                                -webkit-line-clamp: 2;
                                            }
                                        }
                                    }
                                }
                            }

                            .jiantbox {
                                display: none !important;
                                width: 100%;
                                position: absolute;
                                left: 0;
                                margin-top: -30px;
                                top: 50%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                img {
                                    width: 23px;
                                    height: 60px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        .zhezhao {
                width: 100%;
                height: 100%;
                position: fixed;
                z-index: 999;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                .centboxs {
                    width: 80%;
                    height: 420px;
                    background: #FFFFFF;
                    border-radius: 20px;
                    margin: auto;
                    margin-top: 160px;
                    position: relative;
                    .xximg {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        cursor: pointer;
                    }
                    .cgbox {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: absolute;
                        top: 80px;
                        &>img {
                            width: 100px;
                            height: 100%;
                            margin-bottom: 10px;
                        }
                        font-size: 30px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #0CBB76;
                    }
                    .btnbox {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #00ADF1;
                        position: absolute;
                        top: 70%;
                        span {
                            display: block;
                            width: 280px;
                            height: 70px;
                            border: 2px solid #00ADF1;
                            border-radius: 10px; 
                            line-height: 70px;
                            cursor: pointer;
                        }
                        span:nth-child(2) {
                            background: #00ADF1;
                            color: #fff;
                            margin-left: 10px;
                        }
                    }
                }
        }
    }
</style>
