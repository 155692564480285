<template>
    <div>
        <div class="bigbox">
            <div class="titlebox">
                <span>{{ $t('buyer.my.wishList') }}</span>
            </div>
            <div class="qdbox">
                <div class="itemcp" v-for="(item, index) in 15" :key="index">
                    <img src="../../assets/img/shouji.png" @click="toShopDetail" mode="" />
                    <div class="jiage">
                        <text>$180</text>
                        <el-rate v-model="xingxing" disabled />
                        <div class="mswenz" @click="toShopDetail">
                            <span>totes Men's Cirrus Chelsea Ankle Rain Boottotes Cirrus Chel</span>
                        </div>
                    </div>
                    <div class="dbbox">
                        <img src="../../assets/img/shanchu-lan.png" alt="" />
                        <!-- <div class="addCart">
                        <img src="../../assets/img/cart-bai.png" alt="" />
                        <span>Add to cart</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination background layout="prev, pager, next" :total="1000" />
            </div>
        </div>

        <!-- 添加商品第二个弹窗 -->
        <!-- <div class="cgzhezhao" v-show="showtwo">
            <div class="successTc">
            <div class="cuowu">
            <img src="../../assets/img/cuowu-hui.png" alt="" />
            </div>
            <div class="chenggongbox">
            <img src="../../assets/img/zhengque-lan.png" alt="" />
            <span>ltem added to your cart!</span>
            </div>
            <div class="spinfo">
            <div class="leftimg">
                <img src="../../assets/img/diannao.png" alt="" />
            </div>
            <div class="righttext">
                <div class="shopname">
                    <span>Brita Stream Filters, 3 Count (Pack o f1), Grey</span>
                </div>
                <div class="jiagebox">
                    <span>Price:</span>
                    <span>$584.00</span>
                </div>
            </div>
            </div>
            <div class="botmbox">
                <div class="backtext">
                    <span>Back to shopping</span>
                </div>
                <div class="proceedbox">
                    <span>Proceed to Checkout</span>
                </div>
            </div>
        </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            xingxing: 2, //星星数量
            showtwo: false, //弹窗2
        }
    },
    methods: {
        // 去详情页
        toShopDetail() {
            this.$router.push('/index/shopdetail')
        },
    },
}
</script>

<style scoped lang="less">

</style>