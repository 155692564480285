<template>
    <div class="bigbox">
        <div class="titlebox">
            <span>Upload New File</span>
            <span @click="fanhui">Back to uploaded files</span>
        </div>
        <div class="uploadbox">
            <div class="dragbox">
                <span>Drag & drop your files</span>
            </div>
            <div class="uploads">
                <el-upload
                    v-model:file-list="fileList"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                >   
                    <span style="font-size: 60px;color: #969696;">+</span>
            </el-upload>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data(){
            return {
                fileList: [
                    {
                        name: 'food.jpeg',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    {
                        name: 'plant-1.png',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    {
                        name: 'food.jpeg',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    {
                        name: 'plant-2.png',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    
                ] 
            }
        },
        methods: {
            // 返回上一页
            fanhui(){
                this.$router.push('/Home/SellerUploadFiles')
            }
        }
    }
</script>

<style lang="less" scoped>
</style>