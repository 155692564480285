<template>
    <div class="phone">
        <div class="bigbox">
            <div class="titles">
                <span>{{ $t('seller.home.shopSetting') }}</span>
                <span>{{ '('+ $t('seller.appliedRefundRequest.visitShop') + ')' }}</span>
            </div>
            <div class="basicinfo">
                <div class="basic">
                    <span>{{ $t('seller.appliedRefundRequest.basicInfo') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.shopSetting.shopSetting') }}</span>
                        <input type="text" v-model="shopName">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.shopSetting.shopLogo') }}</span>
                        <el-upload
                            v-model:file-list="shopLogo"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess"
                            :on-error="uploadError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.shopSetting.shopPhone') }}</span>
                        <input type="text" :placeholder="$t('agent.seller.phone')" v-model="shopPhone">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.shopSetting.shopAddress') }}*</span>
                        <input type="text" v-model="shopAddress">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.shopSetting.metaTitle') }}*</span>
                        <input type="text" v-model="metaTitle">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                        <textarea name="" id="" cols="30" rows="10" v-model="metaMiaosu"></textarea>
                    </div>
                </div>
            </div>
            <div class="bannerbox">
                <div class="banner">
                    <span>{{ $t('seller.appliedRefundRequest.bannerSettings') }}</span>
                </div>
                <div class="updatebox">
                    <div class="scbox">
                        <el-upload
                            v-model:file-list="bannerImg"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess1"
                            :on-error="uploadError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="bannerbox">
                <div class="banner">
                    <span>{{ $t('seller.appliedRefundRequest.IDCard') }}</span>
                </div>
                <div class="updatebox">
                    <div class="scbox">
                        <span>{{ $t('seller.appliedRefundRequest.CardFront') }}</span>
                        <el-upload
                            v-model:file-list="frontImg"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess2"
                            :on-error="uploadError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
                <div class="updatebox">
                    <div class="scbox">
                        <span>{{ $t('seller.appliedRefundRequest.CardBack') }}</span>
                        <el-upload
                            v-model:file-list="reverseImg"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess3"
                            :on-error="uploadError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="savebox">
                <span @click="shopSettings">{{ $t('seller.shopSetting.save') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { shopInfos,shopSetting } from "@/api/sellerapi.js";
    import {uploadURL} from '@/api/api.js'
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                uploadURL: uploadURL, //图片上传地址
                shopName: '', //店铺名称
                shopLogo: [], //店铺logo
                shopPhone: '', //店铺手机
                shopAddress: '', //店铺地址
                metaTitle: '', //店铺标题
                metaMiaosu: '', //店铺描述
                bannerImg: [], //店铺轮播图
                frontImg: [], //身份证正面图
                reverseImg: [], //身份证反面图
                uploadLogo: '', //上传的logo图片路径
                uploadBanner: '', //上传的banner图片路径
                uploadFrontCard: '',
                uploadReverseCard: ''
            }
        },
        mounted(){
            this.getShopInfo()
        },
        methods:{
            // 获取店铺设置信息获取
            async getShopInfo(){
                const {data: res} = await shopInfos()
                if(res.status == 200) {
                    this.shopName = res.success.shop_name
                    if(res.success.shop_logo) {
                        this.shopLogo = [{url: res.success.shop_logo}]
                    }else {
                        this.shopLogo = []
                    }
                    this.shopPhone = res.success.shop_phone
                    this.shopAddress = res.success.address
                    this.metaTitle = res.success.meta_title
                    this.metaMiaosu = res.success.meta_description
                    if(res.success.banners) {
                        this.bannerImg = [{url: res.success.banners}]
                    }else {
                        this.bannerImg = []
                    }
                    if(res.success.idcard_front) {
                        this.frontImg = [{url: res.success.idcard_front}]
                    }else {
                        this.frontImg = []
                    }
                    if(res.success.idcard_front) {
                        this.reverseImg = [{url: res.success.idcard_backend}]
                    }else {
                        this.reverseImg = []
                    }
                }else {
                    ElMessage.error(res.message)
                }
               console.log(res)
            },
            // 提交数据
            async shopSettings(){
                if(this.shopAddress == '') return ElMessage.error('Please enter the address')
                if(this.metaTitle == '') return ElMessage.error('Please enter a title')
                const {data: res} = await shopSetting({
                    shop_name: this.shopName, //店铺名字（Shop Name）
                    shop_logo: this.uploadLogo, //店铺logo（Shop Logo）
                    shop_phone: this.shopPhone, //店铺电话（Shop Phone）
                    address: this.shopAddress, //地址（Shop Address）
                    meta_title: this.metaTitle, //店铺标题（Meta Title）
                    meta_description: this.metaMiaosu, //店铺描述（Meta description）
                    banners: this.uploadBanner, //店铺banner（Banners）
                    idcard_front: this.uploadFrontCard, //身份证正面
                    idcard_backend: this.uploadReverseCard //身份证反面
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getShopInfo()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 图片上传失败
            uploadError(){
                ElMessage.warning('Upload failed')
            },
            // 图片上传成功
            uploadSuccess(e){
                ElMessage.success('Uploaded successfully')
                this.uploadLogo = e.success.file_url
            },
            uploadSuccess1(e){
                ElMessage.success('Uploaded successfully')
                this.uploadBanner = e.success.file_url
            },
            uploadSuccess2(e){
                ElMessage.success('Uploaded successfully')
                this.uploadFrontCard = e.success.file_url
            },
            uploadSuccess3(e){
                ElMessage.success('Uploaded successfully')
                this.uploadReverseCard = e.success.file_url
            }
        }
    }
</script>
<style lang="less" scoped>
    .phone {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .titles {
                width: 100%;
                height: 70px;
                text-align: left;
                line-height: 70px;
                font-size: 24px;
                font-weight: 600;
                span:nth-child(2){
                    color: #0277BD;
                    margin-left: 15px;
                }
            }
            .basicinfo {
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #DFDFDF;
                border-radius: 5px;
                .basic {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 2px solid #DFDFDF;
                    font-size: 24px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .shopbox {
                    box-sizing: border-box;
                    padding-left: 30px;
                    padding-bottom: 30px;
                    .itembox {
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding-right: 30px;
                        font-size: 20px;
                        .upload-demo {
                            width: 100%;
                        }
                        input {
                            width: 100%;
                            height: 60px;
                            border: 2px solid #DFDFDF;
                            border-radius: 5px;
                            outline: none;
                            box-sizing: border-box;
                            padding-left: 15px;
                            font-size: 20px;
                            margin-top: 10px;
                        }
                        :deep(.el-upload) {
                            width: 100%;
                            height: 60px;
                            border: 2px solid #DFDFDF;
                            border-radius: 5px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-top: 10px;
                            span:nth-child(1){
                                width: 150px;
                                height: 60px;
                                line-height: 60px;
                                background: #DCDCDC;
                                border-radius: 5px;
                                text-align: center;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                            }
                        }
                        textarea {
                            width: 100%;
                            height: 109px;
                            background: #FFFFFF;
                            border: 2px solid #DFDFDF;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 20px;
                            resize: none;
                            margin-top: 10px;
                        }
                    }
                }
            }
            .bannerbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 40px;
                .banner {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 2px solid #DFDFDF;
                    font-size: 20px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .updatebox {
                    box-sizing: border-box;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-bottom: 30px;
                    .upload-demo {
                        width: 100%;
                    }
                    .scbox {
                        display: flex;
                        flex-direction: column;
                        &>span {
                            margin-bottom: 25px;
                            font-size: 20px;
                        }
                        margin-top: 20px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        box-sizing: border-box;
                        :deep(.el-upload) {
                            width:100%;
                            height: 60px;
                            border: 2px solid #DFDFDF;
                            border-radius: 5px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            span:nth-child(1){
                                width: 150px;
                                height: 60px;
                                line-height: 60px;
                                background: #DCDCDC;
                                border-radius: 5px;
                                font-size: 20px;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            
            .savebox {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 40px;
                margin-top: 30px;
                span {
                    width: 200px;
                    height: 60px;
                    background: #0277BD;
                    border-radius: 4px;
                    line-height: 60px;
                    text-align: center;
                    color: #fff;
                    font-size: 24px;
                    margin-right: 30px;
                }
            }
        }
    }
</style>