<template>
    <div class="phone">
        <div class="allbox">
            <div class="titles">
                <span>{{ $t('seller.product.products') }}</span>
            </div>
            <div class="addbox">
                <div class="itemtwo" @click="toAddNewProducts">
                    <span>+</span>
                    <span>{{ $t('seller.product.addNewProduct') }}</span>
                </div>
                <div class="itemtwo" @click="toAddHouseProducts">
                    <span>+</span>
                    <span>{{ $t('seller.product.copyNewProduct') }}</span>
                </div>
            </div>
            <div class="tabbigbox">
                <div class="tabtitle">
                    <span>{{ $t('seller.product.products') }}</span>
                    <input type="text" :placeholder="$t('seller.product.search')" v-model="keyword" @input="inputChnage">
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="main_img" :label="$t('seller.product.productName')" width="120">
                            <template #default="scope">
                                <img class="imgs" :src="scope.row.main_img">
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_name" label="" width="320">
                            <template #default="scope">
                                <span>{{ scope.row.goods_name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="category_name" :label="$t('seller.product.allCategory')" width="220px">
                            <template #default="scope">
                                <span>{{ scope.row.category_name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="current_qty" :label="$t('seller.product.currentQty')" width="130">
                            <template #default="scope">
                                <span>{{ scope.row.current_qty}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="unit_price" :label="$t('seller.copyNewProduct.basePrice')" width="160">
                            <template #default="scope">
                                <span>{{ scope.row.unit_price}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="approval" :label="$t('seller.paymentHistory.approval')" width="200">
                            <template #default="scope">
                                <span class="span1">{{ scope.row.approval }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sj_status" :label="$t('seller.product.published')" width="160">
                            <template #default="scope">
                                <div>
                                    <el-switch v-model="scope.row.sj_status" @change="publishedChange(scope.row.id)"/>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="featured" :label="$t('seller.product.featured')" width="140">
                            <template #default="scope">
                                <div>
                                    <el-switch v-model="scope.row.featured" @change="featuredChange(scope.row.id)"/>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" :label="$t('seller.myLoan.options')" width="200">
                            <template #default="scope">
                                <div class="img2">
                                    <img src="../../../assets/img/history-yanjing.png" @click="lookDetail(scope.row.id)">
                                    <img src="../../../assets/img/seller-bianji-lv.png" @click="toAddNewProducts(scope.row)" v-if="scope.row.goods_type == 1">
                                    <img src="../../../assets/img/seller-fuzhi-cheng.png" @click="copyGoods(scope.row)" v-if="scope.row.goods_type == 1">
                                    <img src="../../../assets/img/history-shanchu.png" @click="deleteChange(scope.row)">
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    class="my-pagination"
                    layout="prev, pager, next" 
                    :total="count" 
                    @prev-click="onPrevClick"
                    @next-click="onNextClick"
                    @current-change="currentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { sellerGoods,goodsDel,goodsSelfCp,goodsFeatured,goodsPublished } from '@/api/sellerapi.js'
    import { ElMessage,ElMessageBox } from 'element-plus'
    export default {
        data(){
            return {
                keyword: '', //搜索键值
                page: 1, //分页
                count: 0, //总条数
                tableData: []
            }
        },
        mounted(){
            this.getGoodsList()
        },
        methods: {
            // 获取商品列表数据
            async getGoodsList(){
                const {data: res} = await sellerGoods({
                    page: this.page,
                    keyword: this.keyword
                })
                // console.log(res)
                if(res.status == 200) {
                    this.tableData = res.success.list
                    for(var i = 0;i < this.tableData.length; i++) {
                        if(this.tableData[i].sj_status == 1) {
                            this.tableData[i].sj_status = true
                        }else {
                            this.tableData[i].sj_status = false
                        }
                        if(this.tableData[i].featured == 1) {
                            this.tableData[i].featured = true
                        }else {
                            this.tableData[i].featured = false
                        }
                    }
                    this.count = parseInt(res.success.count)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 点击上一页事件
            onPrevClick(e){
                this.page = e
                this.getGoodsList()
            },
            // 点击下一页事件
            onNextClick(e){
                this.page = e
                this.getGoodsList()
            },
            // 点击页数事件
            currentChange(e){
                this.page = e
                this.getGoodsList()
            },
            // 搜索事件
            inputChnage(){
                this.page = 1
                this.getGoodsList()
            },
            // 查看详情
            lookDetail(id){
                this.$router.push({path: '/index/shopdetail', query: {id:id}})
            },
            // 删除商品
            deleteChange(e){
                ElMessageBox.confirm(
                    'Are you sure to delete it?',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }).then(async ()=> {
                    const {data: res} = await goodsDel({
                            id: e.id
                    })
                    if(res.status == 200) {
                        ElMessage.success(res.message)
                        this.getGoodsList()
                    } else {
                        ElMessage.error(res.message)
                    }
                }).catch(()=> {
                    ElMessage({
                        type: 'info',
                        message: 'Delete canceled',
                    })
                })
            },
            // 添加和编辑产品
            toAddNewProducts(e){
                this.$router.push({path: '/Home/SellerAddProduct', query: {id: e.id}})
            },
            // 添加内部产品
            toAddHouseProducts(){
                this.$router.push('/Home/AddNewInHousePorducts')
            },
            // 复制产品
            async copyGoods(e){
                const {data: res} = await goodsSelfCp({
                    id: e.id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getGoodsList()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 商品列表精选推荐
            async featuredChange(id){
                const {data: res} = await goodsFeatured({
                    id: id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getGoodsList()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 商品列表发表
            async publishedChange(id){
                const {data: res} = await goodsPublished({
                    id: id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getGoodsList()
                }else {
                    ElMessage.error(res.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .phone {
        .allbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            background: #fff;
            .titles {
                width: 100%;
                font-size: 24px;
                font-family: Arial;
                font-weight: 400;
                color: #0277BD;
                text-align: left;
                margin-top: 30px;
                font-weight: 600;
            }
            .addbox {
                width: 100%;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                .itemtwo {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 240px;
                    background: #FFFFFF;
                    border: 2px solid #DFDFDF;
                    border-radius: 5px;
                    margin-top: 20px;
                    span:nth-child(1){
                        display: inline-block;
                        width: 80px;
                        height: 80px;
                        background: #0277BD;
                        border-radius: 50%;
                        font-size: 40px;
                        font-weight: 600;
                        color: #fff;
                        text-align: center;
                        line-height: 80px;
                    }
                    span:nth-child(2){
                        font-size: 24px;
                        font-family: Arial;
                        color: #0277BD;
                        margin-top: 20px;
                    }
                }
            }
            .tabbigbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 40px;
                box-sizing: border-box;
                .tabtitle {
                    width: 100%;
                    height: 80px;
                    font-size: 24px;
                    border-bottom: 1px solid #DFDFDF;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 30px;
                    input {
                        width: 490px;
                        height: 60px;
                        border: 2px solid #DFDFDF;
                        border-radius: 4px;
                        font-size: 24px;
                        box-sizing: border-box;
                        padding-left: 10px;
                        outline: none;
                    }
                }
                .tabbox {
                    margin-top: 15px;
                    text-align: left;
                    .imgs {
                        width: 90px;
                    }
                    .span1 {
                        display: inline-block;
                        font-size: 20px;
                        font-family: Arial;
                        color: #FFFFFF;
                        background: #0ABB75;
                        border-radius: 4px;
                        padding: 5px 10px;
                    }
                    .img2 {
                        display: flex;
                        img{
                            width: 40px;
                            height: 40px;
                            margin: 0 10px;
                        }
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 40px;
                        height: 40px;
                    }
                    :deep(.number) {
                        font-size: 24px !important;
                    }
                    :deep(.is-first) {
                        width: 40px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 24px !important;
                    }
                }
            }
        }
    }
</style>