<template>
 <!-- 卖家主页 -->
    <div class="phone">
        <div class='bodys'>
            <div class="logo">
                <img :src="logo">
                <div class="imgx">
                    <span class="span1">{{ name }}</span>
                    <el-rate v-model="xingxing" disabled/>
                    <span class="span2">{{ address }}</span>
                </div>
            </div>
            <div class="bannerbox" v-if="this.banner != ''">
                <el-carousel height="400px">
                    <el-carousel-item v-for="(item,index) in banner" :key="index">
                        <img :src="item.banner_img" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="titlebox">
                <span>{{ $t('buyer.shop.allProduct') }}</span>
            </div>
            <div class="qdbox">
                <div class="itemcp" v-for="item in goodsList" :key="item.id">
                    <img :src="item.main_img" @click="toShopDetail(item.id)" />
                    <div class="jiage">
                        <text>{{ item.primary }}</text>
                        <el-rate v-model="item.goods_rating" disabled />
                        <div class="mswenz" @click="toShopDetail(item.id)">
                            <span>{{ item.goods_name }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="18"
                    class="my-pagination"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { shopInfo, shopGoods } from '@/api/buyerapi.js'
    export default {
        data () {
            return {
                id: '', //首页传过来的店铺id
                logo: '', //logo图
                banner: '', //banner
                name: '', //名称
                address: '', //店铺地址
                goodsList: [], //
                total: 10, //总数
                page: 1, //分页
            }
        },
        mounted(){
            this.id = this.$route.query.id
            this.getShopInfo()
            this.getShopGoods()
        },
        watch: {
            "$route.query.id": {
                handler(newName,oldName){
                    if(newName !== oldName) {
                        this.id = this.$route.query.id
                        this.getShopInfo()
                        this.getShopGoods()
                    }
                }
            }
        },
        methods:{
            // 去详情页
            toShopDetail(id) {
                this.$router.push({path:'/index/shopdetail', query: {id: id}})
            },
            // 获取店铺信息
            async getShopInfo(){
                const {data: res} = await shopInfo({
                    id: this.id
                })
                this.logo = res.success.shop_logo
                if(!res.success.banners) {
                    this.banner = []
                }else {
                    this.banner = [{banner_img: res.success.banners}]
                }
                this.name = res.success.shop_name
                this.address = res.success.address
                this.xingxing = res.success.shop_rating
                console.log(res,'bbbbbb')
            },
            // 获取商品信息
            async getShopGoods(){
                const {data: res} = await shopGoods({
                    id: this.id,
                    page: this.page
                })
                if(res.status == 200) {
                    this.goodsList = res.success.list
                    this.total = parseInt(res.success.count)
                    this.page = res.success.page
                }
                console.log('aaaaaaa',res)
            },
            // 上一页
			prevChange(e){
				this.page = e
				this.getShopGoods()
			},
			// 下一页
			nextChange(e){
				this.page = e
				this.getShopGoods()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
				this.getShopGoods()
			},
        }
    }
</script>

<style lang="less" scoped>
    .phone {
        .bodys {
            width: 96%;
            margin: auto;
            .logo {
                width: 100%;
                height: 350px;
                background: #fff;
                margin-top: 30px;
                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-top: 60px;
                }
                .imgx {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 25px;
                    .span1 {
                        font-size: 46px;
                        font-family: Arial;
                    }
                    .span2 {
                        font-size: 28px;
                        font-family: Arial;
                    }
                }
            }
            .bannerbox {
                width: 100%;
                margin-top: 20px;
            }
            .titlebox {
                font-size: 28px;
                font-family: Arial;
                font-weight: bold;
                color: #000000;
                text-align: left;
                margin-top: 50px;
            }
        }

        .qdbox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 2%;

            .itemcp {
                width: 32%;
                min-height: 320px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                padding: 0px 0px 20px 0px;
                background: #fff;
                & > img {
                    width: 180px;
                    height: 200px;
                    cursor: pointer;
                    margin-top: 10px;
                }

                .jiage {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 20px;
                    box-sizing: border-box;
                    padding-left: 10px;

                    & > text {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #53bef4;
                    }

                    .mswenz {
                        width: 100%;
                        height: 50px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        font-size: 22px;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        cursor: pointer;
                    }
                }
            }
        }

        .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 40px;
                        height: 40px;
                    }
                    :deep(.number) {
                        font-size: 28px !important;
                    }
                    :deep(.is-first) {
                        width: 40px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 28px !important;
                    }
                }
            }

        .cgzhezhao {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999999;
            background: rgba(0, 0, 0, 0.6);
            box-sizing: border-box;

            .successTc {
                width: 700px;
                background: #ffffff;
                border-radius: 5px;
                margin: auto;
                margin-top: 200px;

                .cuowu {
                    width: 100%;
                    height: 50px;
                    position: relative;

                    img {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        right: 25px;
                        top: 15px;
                        cursor: pointer;
                    }
                }

                .chenggongbox {
                    img {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 25px;
                    }

                    font-size: 24px;
                    font-family: Arial;
                    font-weight: 500;
                    color: #000000;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .spinfo {
                    width: 600px;
                    height: 180px;
                    border-top: solid 1px #e6e6e6;
                    border-bottom: solid 1px #e6e6e6;
                    margin: auto;
                    margin-top: 30px;
                    display: flex;
                    align-items: center;

                    .leftimg {
                        img {
                            width: 113px;
                            height: 113px;
                        }
                    }

                    .righttext {
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .shopname {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #000000;
                            line-height: 30px;
                            width: 420px;
                            text-align: left;
                            overflow: hidden;
                            /*line-height: 15px;*/
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            /*重新定义-webkit私有盒模型*/
                            -webkit-box-orient: vertical;
                            /*内容之间的排列顺序，vertical垂直方向排列*/
                            -webkit-line-clamp: 2;
                            /*在第几行末尾显示省略号...*/
                            word-break: break-all;
                        }

                        .jiagebox {
                            margin-top: 25px;

                            span:nth-child(1) {
                                font-size: 14px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #969696;
                                margin-right: 10px;
                            }

                            span:nth-child(2) {
                                font-size: 24px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #04adf1;
                            }
                        }
                    }
                }

                .botmbox {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .backtext {
                        width: 180px;
                        height: 40px;
                        border: solid 1px #00adf1;
                        color: #00adf1;
                        text-align: center;
                        line-height: 40px;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .proceedbox {
                        width: 180px;
                        height: 40px;
                        background: #00adf1;
                        color: #fff;
                        text-align: center;
                        line-height: 40px;
                        border-radius: 5px;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>